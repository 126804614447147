import { render, staticRenderFns } from "./modal-stowage.vue?vue&type=template&id=2579a9b5&"
import script from "./modal-stowage.vue?vue&type=script&lang=js&"
export * from "./modal-stowage.vue?vue&type=script&lang=js&"
import style0 from "./modal-stowage.vue?vue&type=style&index=0&id=2579a9b5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports