<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />

    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-extended"
    >
      <CRow>
        <CCol md="12" lg="12" class="text-center" v-show="items.length != 0">
          <CCarousel 
            v-if="items.length != 0"
            arrows
            animate
            height="400px"
            controls
            ref="myCarousel"
            class="mb-5"
          >   
            <CCarouselItem 
              v-for="item in items" 
              :key="item.OrderDocumentId" 
              v-show="item.PhotoRouteOrigin != ''"
            >
              <div>
                <img :src="item.PhotoRoute" width="500px" height="400px"/>
                <div class="d-flex align-items-center justify-content-end text-center"
                  style="width: 500px;  background-color: #101010;  color: white; margin-left: 3.4rem !important;"
                >
                  <p class="col-sm-11 px-0 m-1">{{ item.DocumentDescription }}</p>
                  <CButton v-if="!isAnulled" color="wipe" class="m-1" size="sm" @click="deleteImage(item)">
                    <CIcon name="cil-trash" />
                  </CButton>
                </div>
              </div>
            </CCarouselItem>
          </CCarousel>
        </CCol>
        <CCol md="12" lg="12" class="text-center" v-show="items.length == 0">
          <CCarousel 
            arrows
            height="400px"
            controls
            class="mb-5"
          >   
          </CCarousel>
        </CCol>

      </CRow>

      <template #footer>
        <CButton color="wipe" @click="closeModal">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import inspectionValidation from '@/_validations/inspection/inspectionValidation';
import { alertPropertiesHelpers } from '@/_helpers/funciones';

function data() {
  return {
    showModal: false,
    titleModal: '',
    loadingOverlay: false,
    isAnulled: false,
    imageIncidence: {
      OrderCircuitId: '',
      RouteImage: '',
    },

    items: [],
    OrderCircuitId: '',
  };
}

function closeModal() {
  this.refreshComponent();
  this.$emit('child-refresh', true);
  this.showModal=false;
}

function refreshComponent() {
  this.imageIncidence.OrderCircuitId = '';
  this.imageIncidence.RouteImage = '';
  this.titleModal= '';
  this.loadingOverlay= false;
}

async function getIncidenceById() {
  
  this.loadingOverlay = true;
  let incidenceData = Array;
  await this.$http.get("VisitShipmentOrderIncidence-by-Id", { OrderCircuitId: this.imageIncidence.OrderCircuitId })
    .then(response => {
      incidenceData = [...response.data.data];
      if(incidenceData[0].DocumentJson.length >0){
        let imagesList = Array;
        imagesList = incidenceData[0].DocumentJson;
        let arreglo;
        let i=0;

        arreglo = imagesList.map(imagesList => Object.assign({}, arreglo, {
          Nro: i++,
          PhotoRoute: imagesList.Route ? `${this.$store.getters["connection/getBase"]}${imagesList.Route.replace('Public/', '', null, 'i')}` : '',
          PhotoRouteOrigin: imagesList.Route ? imagesList.Route : '',
          OrderDocumentId: imagesList.OrderDocumentId,
          DocumentDescription: imagesList.DocumentDescription,
        })); 
        this.items = arreglo;
      }else{
        this.closeModal();
        throw this.$t('label.noRecordsAvailble');
      }
    }).catch( err => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    this.loadingOverlay = false;
}

async function deleteImage(item){
  this.$swal.fire(
    alertPropertiesHelpers(this, {
      text: `${this.$t('label.deleteImage')}`,
    })
  ).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let res = [];
      let DocumentJson = [];

      DocumentJson = {
        OrderCircuitId: this.imageIncidence.OrderCircuitId ,
        OrderDocumentId: item.OrderDocumentId,
        DocumentRoute: item.PhotoRouteOrigin,
        DocumentDescription: item.DocumentDescription,
        Status: 0
      };

      this.$http.put("VisitOrderIncidenceDocument-update", DocumentJson, { root: 'DocumentJson' })
      .then(response => {
        res = [...response.data.data];
        this.items = [];
        this.getIncidenceById();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        
        this.loadingOverlay= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingOverlay= false;
      }); 
    }
  });  
}

export default {
  name: 'modal-list-incidence-images',
  components: {
  },
  data,
  props: {
    modal: null,
    edit: Boolean,
    anulled: Boolean,
  },
  mixins: [General],
  directives: UpperCase,
  validations:inspectionValidation,
  watch: {
    modal: async function () {
      if (this.modal) {
        this.refreshComponent();
        this.imageIncidence.OrderCircuitId = this.modal.OrderCircuitId; 
        this.titleModal = this.modal.IncidenceName;
        this.isAnulled = this.anulled;
        await this.getIncidenceById();
        this.showModal = true; 
        
        this.$emit('closeModal');
      }
    },
  },
  methods: {
    closeModal,
    refreshComponent,
    getIncidenceById,
    deleteImage,
  },
  computed: {
   
  },
};
</script>
<style lang="scss" scope>
.w-80 {
  width: 80% !important;
}
.carousel-control-prev, .carousel-control-next {
  width: 5%;
}
.carousel-control-next-icon {
  background-image: url("/img/iconos/NextIcon.svg");
}
.carousel-control-prev-icon {
  background-image: url("/img/iconos/PreviousIcon.svg");
}
</style>
