<template>
<div class="container-fluid">
  <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
  <CRow>
    <CCol sm="6" v-if="showLayout" class="pr-0">
      <table class="info-table">
        <thead>
          <tr>
            <th>{{$t('label.description')}}</th>
            <th>{{$t('label.values')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t('label.'+this.leftTable[0][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                :value.sync="sender"
                v-model.trim="$v.sender.$model"
                :is-valid="hasError($v.sender)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[1][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                :value.sync="recipient"
                v-model.trim="$v.recipient.$model"
                :is-valid="hasError($v.recipient)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[2][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="timePreparation"
                :value.sync="timePreparation"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[3][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="controlReference"
                :value.sync="controlReference"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[4][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                :value.sync="comunicationAgreement"
                v-model.trim="$v.comunicationAgreement.$model"
                :is-valid="hasError($v.comunicationAgreement)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[5][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                :value.sync="messageReference"
                v-model.trim="$v.messageReference.$model"
                :is-valid="hasError($v.messageReference)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[6][0]) }}</td>
            <td>
              <CSelect
                :options="selectMTI"
                v-model.trim="$v.messageTypeIdentifier.$model"
                :value.sync="messageTypeIdentifier"
                :is-valid="hasError($v.messageTypeIdentifier)"
                :class="messageTypeIdentifierValid"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[7][0]) }}</td>
            <td>
              <CSelect
                :options="selectMTV"
                :value.sync="messageTypeVersion"
                v-model.trim="$v.messageTypeVersion.$model"
                :is-valid="hasError($v.messageTypeVersion)"
                @change="cambioMTTV(($event.target.value))"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[8][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="messageTypeReleaseNumber"
                :value.sync="messageTypeReleaseNumber"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[9][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="controllingAgency"
                :value.sync="controllingAgency"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[10][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="associationAssingned"
                :value.sync="associationAssingned"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[11][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                :value.sync="documentNumber"
                v-model.trim="$v.documentNumber.$model"
                :is-valid="hasError($v.documentNumber)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[12][0]) }}</td>
            <td>
              <CSelect
                :options="selectMT"
                :value.sync="messageType"
                v-model.trim="$v.messageType.$model"
                :is-valid="hasError($v.messageType)"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.leftTable[13][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="messageCompilation"
                :value.sync="messageCompilation"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>
              <div class="w-50 float-left d-flex justify-content-center">
                <label class="pt-2">
                  {{ $t('label.'+this.rightTable[11][0]) }}
                </label>
              </div>
              <div class="w-50 float-right text-center">
                <CInput 
                  v-uppercase
                  v-model.trim="lines"
                  :value.sync="lines"
                  disabled
                />
              </div>
            </td>
            <td>
              <div class="w-50 float-left d-flex justify-content-center">
                <label class="pt-2">
                  {{ $t('label.'+this.rightTable[12][0]) }}
                </label>
              </div>
              <div class="w-50 float-right">
                <CInput 
                v-uppercase
                v-model.trim="containers"
                :value.sync="containers"
                disabled
              />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </CCol>
    <CCol sm="6" v-if="showLayout" class="pl-0">
      <table class="info-table">
        <thead>
          <tr>
            <th>{{$t('label.description')}}</th>
            <th>{{$t('label.values')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t('label.'+this.rightTable[0][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                :value.sync="dischargeVoyage"
                v-model.trim="$v.dischargeVoyage.$model"
                :is-valid="hasError($v.dischargeVoyage)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.rightTable[2][0]) }}</td>
            <td>
              <v-select
                :placeholder="$t('label.select')"
                :options="computedPlaceDeparture"
                v-model.trim="$v.placeDeparture.$model"
                :is-valid="hasError($v.placeDeparture)"
                :value.sync="placeDeparture"
                :class="placeDepartureValid"
              >
                <template slot="option" slot-scope="option">
                  <div class="row justify-content-between">
                    <div class="col-sm-2">
                      <CImg
                        :src="option.icon"
                        width="40"
                        height="25"
                        class="mr-2"
                      />
                    </div>
                    <div class="col-sm-10 text-left">
                      {{ option.label }}
                    </div>
                  </div>
                </template>
              </v-select>
            </td>
          </tr>
          <tr>
            <td>ACTUAL TIME OF DEPARTURE</td>
            <td>
              <vue-datepicker 
                  type="datetime"
                  header
                  :lang="this.$i18n.locale"
                  :editable="false"
                  :clearable="false"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="DD/MM/YYYY HH:mm"
                  time-title-format="DD/MM/YYYY HH:mm"
                  v-model.trim="$v.actualTimeDeparture.$model"
                  :is-valid="hasError($v.actualTimeDeparture)"
                  :disabled-date="dateRangeActualDeparture"
                  :append-to-body="false"
                  :class="actualTimeDepartureValid"
                >
              </vue-datepicker>
              <div v-if="!$v.actualTimeDeparture.required" class="invalido">
                {{$t('label.required')}}
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.rightTable[3][0]) }}</td>
            <td>
              <vue-datepicker 
                  type="datetime"
                  header
                  :lang="this.$i18n.locale"
                  :editable="false"
                  :clearable="false"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="DD/MM/YYYY HH:mm"
                  time-title-format="DD/MM/YYYY HH:mm"
                  v-model.trim="$v.estimateTimeDeparture.$model"
                  :is-valid="hasError($v.estimateTimeDeparture)"
                  :append-to-body="false"
                  :disabled-date="dateRangeEstimateDeparture"
                  :class="estimateTimeDepartureValid"
                >
              </vue-datepicker>
              <div v-if="!$v.estimateTimeDeparture.required" class="invalido">
                {{$t('label.required')}}
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.rightTable[4][0]) }}</td>
            <td>
              <v-select
                :placeholder="$t('label.select')"
                :options="computedNextPort"
                v-model.trim="$v.nextPort.$model"
                :is-valid="hasError($v.nextPort)"
                :value.sync="nextPort"             
                :class="nextPortValid"   
              >
                <template slot="option" slot-scope="option">
                  <div class="row justify-content-between">
                    <div class="col-sm-2 text-left">
                      <CImg
                        :src="option.icon"
                        width="40"
                        height="25"
                        class="mr-2"
                      />
                    </div>
                    <div class="col-sm-10 text-left">
                      {{ option.label }}
                    </div>
                  </div>
                </template>
              </v-select>
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.rightTable[1][0]) }}</td>
            <td>
              <vue-datepicker 
                  type="datetime"
                  header
                  :lang="this.$i18n.locale"
                  :editable="false"
                  :clearable="false"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="DD/MM/YYYY HH:mm"
                  time-title-format="DD/MM/YYYY HH:mm"
                  :append-to-body="false"
                  v-model.trim="$v.actualTime.$model"
                  :is-valid="hasError($v.actualTime)"
                  :disabled-date="dateRangeActualDeparture"
                  :class="actualTimeValid"
                >
              </vue-datepicker>
              <div v-if="!$v.actualTime.required" class="invalido">
                {{$t('label.required')}}
              </div>
            </td>
          </tr>   
          <tr>
            <td>{{ $t('label.ESTIMATEDTIMEOFARRIVAL') }} </td>
            <td>
              <vue-datepicker 
                  type="datetime"
                  header
                  :lang="this.$i18n.locale"
                  :editable="false"
                  :clearable="false"
                  format="DD/MM/YYYY HH:mm"
                  placeholder="DD/MM/YYYY HH:mm"
                  time-title-format="DD/MM/YYYY HH:mm"
                  v-model.trim="$v.estimateTime.$model"
                  :is-valid="hasError($v.estimateTime)"
                  :disabled-date="dateRangeActualDeparture"
                  :append-to-body="false"
                  :class="estimateTimeValid"
                >
              </vue-datepicker>
              <div v-if="!$v.estimateTime.required" class="invalido">
                {{$t('label.required')}}
              </div>
            </td>
          </tr>   
          <tr>
            <td>{{ $t('label.'+this.rightTable[5][0]) }}</td>
            <td>
              <v-select
                :placeholder="$t('label.select')"
                :options="computedCarrierCode"
                v-model.trim="$v.carrierCode.$model"
                :is-valid="hasError($v.carrierCode)"
                :value.sync="carrierCode"    
                :class="carrierCodeValid"
                @input="searchShipping"
              >
              </v-select>
            </td>
          </tr>
          <tr>
            <td>{{$t('label.standardDocumentation')}}</td>
            <td>
              <v-select
                :placeholder="$t('label.select')"
                :options="docOptions"
                v-model.trim="$v.StandardDocumentId.$model"
                :is-valid="hasError($v.StandardDocumentId)"
                :value.sync="StandardDocumentId"    
                :class="StandardDocumentIdValid"
              >
              </v-select>
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.rightTable[8][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="vesselCall"
                :value.sync="vesselCall"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('label.'+this.rightTable[9][0]) }}
            </td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="vesselLloyds"
                :value.sync="vesselLloyds"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td class="tamanioLetra">{{ $t('label.IdMeanfTransportIdentification') }}</td>
            <td>
              <CSelect
                :options="selectMTIdentId"
                v-model.trim="$v.MTIdentId.$model"
                :value.sync="MTIdentId"
                :is-valid="hasError($v.MTIdentId)"
                :class="MTIdentIdValid"
                @change="cambioMTId(($event.target.value))"
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <CInput 
                v-uppercase
                :value.sync="idMeans"
                v-model.trim="$v.idMeans.$model"
                :is-valid="hasError($v.idMeans)"
                :invalid-feedback="$t('label.required')+$t('label.onlyAlphanumeric')"
                :disabled="idMeansDesactivado"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.rightTable[7][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                v-model.trim="vesselName"
                :value.sync="vesselName"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('label.'+this.rightTable[10][0]) }}</td>
            <td>
              <CInput 
                v-uppercase
                :value.sync="loadingVoyage"
                v-model.trim="$v.loadingVoyage.$model"
                :is-valid="hasError($v.loadingVoyage)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </CCol>
  </CRow>
  <CRow class="mt-2 text-right mb-3">
    <CCol sm="12">
      <CButton
        color="add"
        class="mr-2"
        :disabled="desactivado"
        @click="guardar"
        v-if="activaGuardar"
      >
        <CIcon name="cil-file" class="mr-1" />&nbsp; 
        {{ $t('label.generate') }} BAPLIE
      </CButton>
      <CButton
        color="wipe"
        @click="volver"
      >
        <CIcon name="x" class="mr-1" />
        {{$t('button.cancel')}}
      </CButton>
      <!--<CButton
        color="excel"
        v-c-tooltip="{
          content: $t('label.download'),
          placement: 'top',
        }"
        @click="vGenerar(item)"
      >
        <CIcon name="cil-cloud-download" />
      </CButton>-->
    </CCol>
  </CRow>
</div>
</template>

<script>
import { mapState } from "vuex";
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import GenerarBaplieValidations from '@/_validations/planificacion-estiba/generarBaplieValidations';

// Data
function data() {
  return {
    info: {},
    leftTable: [],
    leftRowNum: 14,
    rightTable: [],
    rightRowNum: 14,
    showLayout: false,
    loadingOverlay: false,
    sender: '',
    recipient: '',
    comunicationAgreement: '',
    controlReference: '',
    messageReference: '',
    dischargeVoyage: '',
    actualTime: '',
    actualTimeDeparture: '',
    estimateTimeDeparture: '',
    StandardDocumentId: '',
    estimateTime: '',
    loadingVoyage: '',
    selectMTI: [],
    selectMTV: [],
    selectMT: [],
    selectMTIdentId: [],
    messageType: '',
    messageTypeIdentifier: '',
    messageTypeVersion: '',
    messageTypeReleaseNumber: '',
    placeDeparture: '',
    selectPlaceDeparture: [],
    nextPort: '',
    selectNextPort: [],
    documentNumber: '',
    messageCompilation: '',
    vesselLloyds: '',
    vesselCall: '',
    MTIdentId: '',
    vesselName: '',
    lines: '',
    containers: '',
    selectCarrierCode: [],
    carrierCode: '',
    timePreparation: '',
    ServiceId: '',
    global: true,
    totalInfo: [],
    VisitId: '',
    idMeans: '',
    idMeansDesactivado: true,
    docOptions: [],
    ShippingLineId: '',
    previousDate: new Date(),
    laterDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    previousDateEstimate: new Date(),
    activaGuardar: false
  };
}

// methods:
async function getEdiFileInfo() {
  this.loadingOverlay = true;
  await this.$http
    .ejecutar("GET", "EdiFileInformationForGeneration-by-VisitId", { VisitId: this.VisitId,})

    .then((response) => {
      this.setData(response.data.data[0]);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingOverlay = false;
    });
}

async function searchShipping(event) {
  this.loadingOverlay = true;
  await this.listarDocs(event.value);
  this.loadingOverlay = false;
}

async function getMessageTypeVersion() {
  this.loadingOverlay = true;

  this.selectMTI = [];
  this.selectMT = [];
  this.selectMTV = [];
  await this.$http
    .ejecutar("GET", "TpEdiGeneration-list", {})
    .then((response) => {
      let listado = response.data.data[0];
      this.selectMTI.push({
        label: listado.TpEdiName,
        value: listado.TpEdiId,
      })
      this.messageTypeIdentifier = this.selectMTI[0].value;

      if (listado.MessageJson.MessageJson.length > 0) {
        this.selectMT = listado.MessageJson.MessageJson.map(listado => Object.assign({}, this.selectMT, {
          value: listado.MessageFunctCode,
          label: listado.MessageFunctName,
        }));
      }
      this.messageType = this.selectMT[0].value;

      if (listado.VersionJson.VersionJson.length > 0) {
        this.selectMTV = listado.VersionJson.VersionJson.map(listado => Object.assign({}, this.selectMTV, {
          value: listado.TpEdiVersionId,
          label: listado.Version,
          MessageTypeNumber: listado.MessageTypeNumber,
          ControlAgencyCode: listado.ControlAgencyCode,
          AssociationAssignedCode: listado.AssociationAssignedCode,
        }));
      }
      this.messageTypeVersion = this.selectMTV[0].value;
      this.cambioMTTV(this.selectMTV[0].value)
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingOverlay = false;
    });
}

async function getMTIdentId() {
  this.loadingOverlay = true;

  this.selectMTIdentId = [];
  await this.$http
    .ejecutar("GET", "EdiMeansTransport-list", {})
    .then((response) => {
      let listado = response.data.data;
      for (let i=0; i < listado.length; i++) {
        this.selectMTIdentId.push({
          label: listado[i].MTIdentName,
          value: listado[i].MTIdentId,
        })
      }
      this.MTIdentId = this.selectMTIdentId[0].value;
      this.idMeans = this.vesselLloyds;
      this.idMeansDesactivado = true;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingOverlay = false;
    });
}

function setData(ediInfo) {
  //console.log(ediInfo);
  this.ServiceId = ediInfo.ServiceId;
  const notAllowed = ['EdiFileId', 'EdiFileName', 'ServiceId', 'VesselFlag', 'VesselId'];
  this.totalInfo = ediInfo;
  //console.log(this.totalInfo);
  let filtered = Object.keys(ediInfo).filter(key => !notAllowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = ediInfo[key];
      return obj;
  }, {});

  let arr = Object.entries(filtered);
  this.leftTable = arr.slice(0, this.leftRowNum);
  this.rightTable = arr.slice(this.rightRowNum);

  this.sender = this.leftTable[0][1];
  this.recipient = this.leftTable[1][1];
  this.timePreparation = this.leftTable[2][1];
  this.controlReference = this.leftTable[3][1];
  this.comunicationAgreement = this.leftTable[4][1];
  this.messageReference = this.leftTable[5][1];
  this.messageTypeReleaseNumber = this.leftTable[8][1];
  this.controllingAgency = this.leftTable[9][1];
  this.associationAssingned = this.leftTable[10][1];
  this.documentNumber = this.leftTable[11][1];
  this.messageCompilation = this.leftTable[13][1];

  this.dischargeVoyage = this.rightTable[0][1];
  this.actualTime = new Date(this.rightTable[1][1]);  
  this.placeDeparture = this.rightTable[2][1];
  this.nextPort = this.rightTable[4][1];
  this.carrierCode = this.rightTable[5][1];
  this.MTIdentId = this.rightTable[6][1];
  this.vesselName = this.rightTable[7][1];
  this.vesselCall = this.rightTable[8][1];
  this.vesselLloyds = this.rightTable[9][1];
  this.loadingVoyage = this.rightTable[10][1];
  this.lines = this.rightTable[11][1];
  this.containers = this.rightTable[12][1];

  this.showLayout = true;
  if (this.rightTable[3][1]!="") {
    let arrFecha = this.rightTable[3][1].split(" ");
    let arrFecha2 = arrFecha[0].split("/");
    let fechaCorrecta = arrFecha2[2]+"-"+arrFecha2[1]+"-"+arrFecha2[0]+" "+arrFecha[1];
    
    this.estimateTimeDeparture = new Date(fechaCorrecta)
    this.previousDateEstimate = new Date(fechaCorrecta);
  }
  //console.log(this.previousDate)
  //EstimatedTimeOfDeparture
  if (this.actualTimeDeparture=="") this.actualTimeDeparture = this.previousDate;
}

function dateRangeActualDeparture(date) {
    const minDate = new Date(this.previousDate);     
    minDate.setHours(0, 0, 0, 0);

    const maxDate = new Date(this.laterDate);     
    maxDate.setHours(0, 0, 0, 0);

    return date < new Date(minDate.getTime()) || date > new Date(maxDate.getTime());
}

function dateRangeEstimateDeparture(date) {
    const minDate = new Date(this.previousDateEstimate);     
    minDate.setHours(0, 0, 0, 0);

    const maxDate = new Date(this.laterDate);     
    maxDate.setHours(0, 0, 0, 0);

    return date < new Date(minDate.getTime()) || date > new Date(maxDate.getTime());
}

function separateStringByCapitalLetter(cadena){
  return cadena.match(/[A-Z][a-z]+/g).join(" ");
}

function volver() {
  this.$emit('child-refresh', true);
}

async function ServiceList() {
  this.loadingOverlay = true;
  this.selectPlaceDeparture = [];
  this.selectNextPort = [];
  await this.$http
    .ejecutar("GET", "ServicePort-list", { ServiceId : this.ServiceId, Filter: 'ACTIVO'  })
    .then((response) => {
      let listado = response.data.data;

      this.selectPlaceDeparture = listado;
      let resultado = listado.filter(x => (x.PortCode == this.placeDeparture || x.PortName == this.placeDeparture));
      if (resultado.length > 0) {
        this.placeDeparture = {
          label: resultado[0].PortName,
          value: resultado[0].PortId,
          icon: ''
        }
      } else {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: "Place Departure No Register in the service",
          type: "error",
        });
      }

      this.selectNextPort = listado;
      resultado = listado.filter(x => (x.PortCode == this.nextPort || x.PortName == this.nextPort));
      if (resultado.length > 0) {
        this.nextPort = {
          label: resultado[0].PortName,
          value: resultado[0].PortId,
          icon: ''
        }
      } else {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: "Next Port No Register in the service",
          type: "error",
        });
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingOverlay = false;
    });
}

async function CarrierList() {
  this.loadingOverlay = true;
  this.selectCarrierCode = [];
  await this.$http
    .ejecutar("GET", "ServiceLine-by-service", { ServiceId : this.ServiceId, Filter: 'ACTIVO'  })
    .then((response) => {
      let listado = response.data.data;
      this.selectCarrierCode = listado;

      let resultado = listado.filter(x => x.ShippingLineAbbrev == this.carrierCode);
      if (resultado.length > 0) {
        //this.selectCarrierCode = response.data.data;
        this.carrierCode = {
          label: resultado[0].ShippingLineAbbrev,
          value: resultado[0].ServiceLineId,
        }
        this.ShippingLineId = resultado[0].ServiceLineId;
      /*} else {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: "Place Departure No Exist",
          type: "error",
        });
        this.selectCarrierCode='';*/
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingOverlay = false;
    });
}

function retornarFecha(nuevo) {
  let newDay = new Date(nuevo),
        day = ('0'+newDay.getDate()).slice(-2),
        month = ('0'+ (newDay.getMonth() + 1) ).slice(-2),
        year = newDay.getFullYear(),
        hour =  ("0" + (newDay.getHours())).slice(-2),
        min =  ("0" + (newDay.getMinutes())).slice(-2);
  
    let separator = '/';

    return `${day}${separator}${month}${separator}${year} ${hour}:${min}`;
}

function guardar() {
  if (this.$v.$invalid) return false;

  let vactualTime = retornarFecha(this.actualTime);
  let vestimateTime = retornarFecha(this.estimateTime);
  let vestimateTimeDeparture = retornarFecha(this.estimateTimeDeparture);
  let vactualTimeDeparture = retornarFecha(this.actualTimeDeparture);

  //COMPARA ACTUAL TIME
  let fecha10 = vactualTimeDeparture.split(" ");
  let fecha1 = fecha10[0].split("/");
  fecha1 = fecha1[2]+fecha1[1]+fecha1[0];
  let fecha20 = vactualTime.split(" ");
  let fecha2 = fecha20[0].split("/");
  fecha2 = fecha2[2]+fecha2[1]+fecha2[0];

  if (fecha2 < fecha1) {
    this.loadingOverlay = false;
    this.actualTime = '';
    this.$notify({
        group: 'container',
        title: 'Error!',
        text: this.$t('label.errosDatePleaseCheck'),
        type: "error"
      });
    return;
  } else if (fecha2==fecha1) {
    let hora1 = fecha10[1].split(":");
    hora1 = hora1[0]+hora1[1];
    let hora2 = fecha20[1].split(":");
    hora2 = hora2[0]+hora2[1];
    
    if (hora2 < hora1) {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: 'Error!',
        text: this.$t('label.errosHourPleaseCheck'),
        type: "error"
      });
      return;
    }
  } 
  //COMPARA ACTUAL TIME
  fecha10 = vestimateTimeDeparture.split(" ");
  fecha1 = fecha10[0].split("/");
  fecha1 = fecha1[2]+fecha1[1]+fecha1[0];
  fecha20 = vestimateTime.split(" ");
  fecha2 = fecha20[0].split("/");
  fecha2 = fecha2[2]+fecha2[1]+fecha2[0];

  if (fecha2 < fecha1) {
    this.loadingOverlay = false;
    this.estimateTime = '';
    this.$notify({
        group: 'container',
        title: 'Error!',
        text: this.$t('label.errosDatePleaseCheck'),
        type: "error"
      });
      return;
  } else if (fecha2==fecha1) {
    let hora1 = fecha10[1].split(":");
    hora1 = hora1[0]+hora1[1];
    let hora2 = fecha20[1].split(":");
    hora2 = hora2[0]+hora2[1];

    if (hora2 < hora1) {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: 'Error!',
        text: this.$t('label.errosHourPleaseCheck'),
        type: "error"
      });
      return;
    }
  } 

  this.loadingOverlay = true;
  let GenerateEdiFileJson = {
      VisitId: this.VisitId,
      Sender: this.sender,
      Recipient: this.recipient,
      InterchangeControlReference: this.controlReference,
      CommunicationsAgreementId: this.comunicationAgreement,
      MessageReferenceNumber: this.messageReference,
      TpEdiVersionId: this.messageTypeVersion,
      DocumentNumber: this.documentNumber,
      MessageType: this.messageType,
      DischargeVoyage: this.dischargeVoyage,
      ActualDatetimeArrivalSendersPort: vactualTime,
      EstimatedDatetimeArrivalNextPortOfCall: vestimateTime,
      EstimatedDatetimeDepartureSendersPort: vestimateTimeDeparture,
      ActualDatetimeDepartureSendersPort: vactualTimeDeparture,
      PlaceOfDeparture: this.placeDeparture.value,
      NextPortOfCall: this.nextPort.value,
      CarrierCode: this.carrierCode.value,
      MTIdentId: this.MTIdentId,
      IdOfMeansOfTransportIdentification: this.idMeans,
      LoadingVoyage: this.loadingVoyage,
      StandardDocumentIdValid: this.StandardDocumentIdValid
  };

  //console.log(GenerateEdiFileJson);return false;
  this.$http.post('VisitEdiFile-insert', GenerateEdiFileJson, { root: 'GenerateEdiFileJson'} )
  .then(response => {
    if(response.data.data.length !== 0){
      let rq = response.data.data[0];
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: rq.Response,
        type: "success"
      });

      this.archivoDescargable(rq.EdiFileName, rq.EdiFileText);   
      this.$emit('child-refresh', true); 
    }else{
      this.$notify({
        group: 'container',
        title: this.$t('label.Warning'),
        text: this.$t('label.NoLoadDistributionPlan'),
        type: "warn",
      });
    }
    
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.loadingOverlay = false;
  });
}

function cambioMTId(valor) {
  this.idMeansDesactivado = true;
  switch (valor) {
    case "1F666AF0-AB05-481D-81D0-A303FE12EF8C":
      this.idMeans = this.vesselCall;
      break;
    case "E186B393-0B0F-4228-9049-88E57EFEDDE0":
      this.idMeans = this.vesselLloyds;
      break;
    case "F4622D41-2F53-4A31-A1E7-DB667AFC4BC9":
      this.idMeans = '';
      this.idMeansDesactivado = false;
      break;
  }
}

function cambioMTTV(valor) {
  let arreglo = this.selectMTV.filter(x => x.value == valor);
  if (arreglo) {
    this.messageTypeReleaseNumber = arreglo[0].MessageTypeNumber;
    this.controllingAgency = arreglo[0].ControlAgencyCode;
    this.associationAssingned = arreglo[0].AssociationAssignedCode;
  }
}

function listarDocs(ServiceLineId) {
  this.docOptions = [];

  let listado = Array;
  this.$http
    .get("StandardDocument-by-ShippingLineId", { ShippingLineId: ServiceLineId })
    .then((response) => {
      listado = [...response.data.data];
      
      this.docOptions = listado.map((item) => Object.assign({}, item, {
          value: item.StandardDocumentId,
          label: item.StandardDocumentName,
      }));
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}

function buscarRegistros() {
  if (this.ediFileId!=null) {
    let listado = Array;
    this.$http
      .get("VisitEdiFile-list", { VisitId: this.VisitId })
      .then((response) => {
        listado = [...response.data.data];
        if (listado.length > 0) this.activaGuardar = true;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      });
  }
}

//COMPUTED
function computedPlaceDeparture() {
  if (this.selectPlaceDeparture.length > 0) {
    return this.selectPlaceDeparture.map((item) => {
      return {
        label: item.PortName,
        value: item.PortId,
        icon:
          (item.PortRoute)
            ? `${this.$store.getters["connection/getBase"]}${item.PortRoute}`
            : '',
      };
    });
  }
}

function computedNextPort() {
  if (this.selectNextPort.length > 0) {
    return this.selectNextPort.map((item) => {
      return {
        label: item.PortName,
        value: item.PortId,
        icon:
          (item.PortRoute)
            ? `${this.$store.getters["connection/getBase"]}${item.PortRoute}`
            : '',
      };
    });
  }
}

function computedCarrierCode() {
  if (this.selectCarrierCode.length > 0) {
    return this.selectCarrierCode.map((item) => {
      return {
        label: item.ShippingLineAbbrev,
        value: item.ServiceLineId
      };
    });
  }
}

function placeDepartureValid() {
  if (this.placeDeparture == null) this.placeDeparture = '';
  return this.placeDeparture == '' ? 'no-valido' : 'valido';
}

function nextPortValid() {
  if (this.nextPort == null) this.nextPort = '';
  return this.nextPort == '' ? 'no-valido' : 'valido';
}

function carrierCodeValid() {
  if (this.carrierCode == null) this.carrierCode = '';
  return this.carrierCode == '' ? 'no-valido' : 'valido';
}

function estimateTimeValid() {
  if (this.estimateTime == null) this.estimateTime = '';
  return this.estimateTime == '' ? 'no-valido' : 'valido';
}

function StandardDocumentIdValid() {
  if (this.StandardDocumentId == null) this.StandardDocumentId = '';
  return this.StandardDocumentId == '' ? 'no-valido' : 'valido';
}

function actualTimeValid() {
  if (this.actualTime == null) this.actualTime = '';
  return this.actualTime == '' ? 'no-valido' : 'valido';
}

function estimateTimeDepartureValid() {
  if (this.estimateTimeDeparture == null) this.estimateTimeDeparture = '';
  return this.estimateTimeDeparture == '' ? 'no-valido' : 'valido';
}

function actualTimeDepartureValid() {
  if (this.actualTimeDeparture == null) this.actualTimeDeparture = '';
  return this.actualTimeDeparture == '' ? 'no-valido' : 'valido';
}

function messageTypeIdentifierValid() {
  if (this.messageTypeIdentifier == null) this.messageTypeIdentifier = '';
  return this.messageTypeIdentifier == '' ? 'no-valido' : 'valido';
}

function MTIdentIdValid() {
  if (this.MTIdentId == null) this.MTIdentId = '';
  return this.MTIdentId == '' ? 'no-valido' : 'valido';
}

export default {
  name: "generar-baplie",
  directives: UpperCase,
  mixins: [General],
  validations: GenerarBaplieValidations,
  data,
  methods: {
    getEdiFileInfo,
    setData,
    separateStringByCapitalLetter,
    getMessageTypeVersion,
    getMTIdentId,
    ServiceList,
    CarrierList,
    volver,
    guardar,
    cambioMTId,
    cambioMTTV,
    retornarFecha,
    listarDocs,
    searchShipping,
    buscarRegistros,
    dateRangeActualDeparture,
    dateRangeEstimateDeparture
  },
  computed: {
    ...mapState({
      ediFileId: (state) => state.visitas.EdiFileId,
      ediFileInfo: state => state.visitas.EdiFileInfo,
      collapsePlanificacion: (state) => state.visitas.collapsePlanificacion,
      Visit: state => state.visitas.VisitId,
    }),
    computedNextPort,
    computedPlaceDeparture,
    computedCarrierCode,
    placeDepartureValid,
    nextPortValid,
    carrierCodeValid,
    estimateTimeValid,
    actualTimeValid,
    estimateTimeDepartureValid,
    actualTimeDepartureValid,
    messageTypeIdentifierValid,
    MTIdentIdValid,
    StandardDocumentIdValid
  },
  watch: {
    collapsePlanificacion: function (newValue) {
      if(!newValue) {
        this.leftTable = [];
        this.rightTable = [];
        this.showLayout = false;
      }
    },
  },
  async mounted() {
    this.VisitId = this.Visit;
    this.activaGuardar = false;
    await this.getEdiFileInfo();    
    await this.CarrierList();
    await this.ServiceList();
    await this.getMessageTypeVersion();
    await this.getMTIdentId();
    await this.listarDocs(this.ShippingLineId);
    await this.buscarRegistros();
    await this.$nextTick(() => { this.$v.$touch() });
  }
};
</script>

<style scoped>
.info-table {
  width: 100%;
  border: 1px solid #616161;
  border-collapse: collapse;
}
.info-table thead {
  background-color: #616161;
  color: #ffffff;
}
.info-table th,
.info-table td {
  width: calc(100% / 2);
  border: 1px solid #616161;
  padding: 10px;
}
.info-table th {
  text-align: left;
}

.info-table tr td:nth-child(1),
.info-table tr td:nth-child(3) {
  background-color: #e5e5e5;
}

.tamanioLetra {
  font-size: 12px
}

td {
  height: 58px;
}

.form-group {
  margin-bottom: 0 !important;
}

.invalido {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
}
</style>