<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CCard class="card-simple">
      <CCardBody>  
        <CRow class="justify-content-center">
          <CCol sm="11" lg="9" style="margin-bottom:10px;">
            <CInput
              :label="$t('label.name')"
              :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
              v-model.trim="$v.StowageUbicationName.$model"
              v-uppercase
              maxlength="250"
              required
              :is-valid="hasError($v.StowageUbicationName)"
              :invalid-feedback="errorMessage($v.StowageUbicationName)"
              :placeholder="$t('label.locationName')"
              add-label-classes="required text-right"
            />
          </CCol>

          <CCol sm="11" lg="1" class="px-0" v-if="editUbicacion" style="margin-bottom:10px;">
            <CButton color="wipe" class="mr-1" size="sm" v-c-tooltip="{content: $t('label.clearFields')}" @click="limpiarDatos" >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </CCol>
          
          <CCol sm="11" lg="2" class="px-0" style="margin-bottom:10px;">
            <CButton color="edit" size="sm" class="mr-1" @click="tablaCollapse = !tablaCollapse" v-c-tooltip="{content: $t('label.locationList')}" >
                {{ cambiaTexto }}
                <CIcon :name="cambiaIcono"/>
              </CButton>
          </CCol>

          <CCol sm="11" lg="9" v-if="editUbicacion">
            <CSelect
                :label="$t('label.status')"
                addLabelClasses="required text-right"
                :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                v-model="Status"
                :value.sync="Status"
                :options="selectOptions"
                :is-valid="statusSelectColor"
              />
          </CCol>
          <CCol sm="11" lg="1" class="px-0"  v-if="editUbicacion" style="margin-bottom:10px;">
            
          </CCol>
           <CCol sm="11" lg="2" class="px-0" v-if="editUbicacion" style="margin-bottom:10px;">
           
          </CCol>
          </CRow> 
      </CCardBody>
    </CCard>
    <CRow class="mx-3">
      <CCol sm="12">
        <CCollapse :show="tablaCollapse" class="mt-5">
          <dataTableExtended
           class="align-center-row-datatable"
            :items="formatedItems"
            :fields="fields"
            :loading="loadingTable"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="5"
            hover
            small
            sorter
            pagination
             
          >
            <template #Status="{item}">
              <td class="center-cell">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Detalle="{item}">
            <td class="py-2 center-cell">
              <CButton color="edit" size="sm" 
                  v-c-tooltip="{content: $t('label.edit')+$t('label.location'), placement: 'top-end'}" 
                  @click="editDatos(item)" >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
          </dataTableExtended>
        </CCollapse>
      </CCol>
    </CRow>
      <!--</CCardBody>
    </CCard>-->
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end footer">
        <CButton outline color="add" class="m-2" :disabled="isSubmit" @click="evaluaStatus">
        <CIcon name="checkAlt"/>&nbsp;
        {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" :disabled="isSubmit" @click="cerrar" class="m-2">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </CCol>
    </CRow>
  </div>  
</template>

<script>
import { DateFormater } from '@/_helpers/funciones';
import UbicacionValidations from '@/_validations/estiba/UbicacionValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';

function fields(){ 
  return [
    { 
      key: 'Row', 
      label: '#',
      filter: false,
      _style: 'width:1%; text-align:center'
    },
    { key: 'StowageUbicationName', label:this.$t('label.location'), _style: 'width:55%;', _classes: 'text-left'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width:10%;', _classes: 'text-left'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:10%;',_classes: 'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width:10%;', _classes: 'text-center'},
    { 
      key: 'Detalle', 
      label: '', 
      sorter: false, 
      filter: false,
      _style: 'width:50px;',
      _classes: 'text-center'
    }
  ];
}
//DATA
function data () {
  return {
    //MODELO
    StowageUbicationId: '',
    StowageUbicationName: '',
    Status: 1,
    //VARIABLES
    selectOptions: [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ],
    muestraTabla: false,
    items: [],
    Loading: false,
    loadingTable: false,
    editUbicacion: false,
    tablaCollapse: false,
    iconoCollapse: 'cil-arrow-circle-top',
    isSubmit: false 
  }
}

function cerrar() {
    this.limpiarDatos();
    this.$emit('child-refresh',true)
}

function limpiarDatos() {
  this.StowageUbicationId='';
  this.StowageUbicationName= null;
  this.Status=1;
  this.editUbicacion = false;
  this.$nextTick(() => { this.$v.$reset() })
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')+' '+this.StowageUbicationName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}

function guardar() {
  //if (this.$v.$invalid) return false;
  
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
     this.Loading = false;
     this.isSubmit = false;

     throw this.$t('label.errorsPleaseCheck');

    }
    let res = [];
    let metodo = '';
    let ruta = '';
    let StowageUbicationJson = [];
    
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.editUbicacion) {
      StowageUbicationJson = {
        StowageUbicationId: this.StowageUbicationId,
        StowageUbicationName: this.StowageUbicationName,
        Status: this.Status
      };
      metodo = 'PUT';
      ruta = "StowageUbication-update";
    } else {
      StowageUbicationJson = {
        StowageUbicationName: this.StowageUbicationName
      };
      metodo = 'POST';
      ruta = "StowageUbication-insert";
    }
  
    this.$http.ejecutar(metodo, ruta, StowageUbicationJson, { root: 'StowageUbicationJson' })
    .then(response => {
      res = [...response.data.data];
      this.limpiarDatos();
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      this.Loading= false;
      this.isSubmit = false;
      this.getUbicaciones();
     
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading= false;
      this.isSubmit = false;
    });
    } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function cambiaIcono() {
  return this.iconoCollapse = (this.tablaCollapse) ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function cambiaTexto() {
  return this.textoCollapse = (this.tablaCollapse) ? this.$t("label.close") : this.$t("label.toView");
}
function editDatos(item) {
    this.StowageUbicationName = item.StowageUbicationName;
    this.Status = item.FgActStowageUbication ? 1 : 0;
    this.StowageUbicationId = item.StowageUbicationId;
    this.editUbicacion = true;
    this.$v.$touch();
}

function getUbicaciones() {
  this.loadingTable = true;

  this.$http.get("StowageUbication-list", { filter: 'ALL' })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.loadingTable = false;
  });
}

function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    Row: item.Nro,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'text-center table-danger': 'text-center'
  }));
}

export default {
  name: 'add-ubicacion',
  mixins: [General],
  data,
  props: ['value'],
  directives: UpperCase,
  validations: UbicacionValidations,
  methods: {
    limpiarDatos,
    evaluaStatus,
    guardar,
    getUbicaciones,
    editDatos,
    cerrar
  },
  computed: {
    cambiaIcono,
    cambiaTexto,
    formatedItems,
    fields
  },
  mounted () {
    this.limpiarDatos();
    if (this.value) {
        this.editDatos(this.value);
    }
    this.getUbicaciones();
  },
}
</script>
<style lang="scss">
/*
.alto {
  height: 200px;
}
*/
.footer{
    margin: 0px;
    padding: 0px;
    border-top: 1px solid;
    border-top-color: #d8dbe0;
    left: 0px;
    vertical-align: middle !important;
}
.center-cell {
  text-align: center;
}
.table-index {
  table {
    td {
      vertical-align: middle !important;
    }
  }
  .table-responsive {
    overflow: hidden;
  }
}
</style>