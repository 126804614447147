<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />

    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-extended"
    >
      <CRow>
        <CCol lg="12" class="text-center" v-show="items.length != 0">
          <CCarousel 
            v-if="items.length !=0"
            arrows
            animate
            height="400px"
            controls
            ref="myCarousel"
          >            
            <CCarouselItem v-for="item in items" :key="item.VisitCargoInspectionPhoto">
              <img :src="item.PhotoRoute" width="500" height="400"/>
              <div v-show="showButtonDelete" style="text-align: center;">
                <CButton color="wipe" class="m-1" size="sm" style="position: absolute;top: 3%; right: 8%;" @click="deleteImage(item)">
                  <CIcon name="cil-trash" />
                </CButton>
              </div>
            </CCarouselItem>
          </CCarousel>
        </CCol>
        <CCol md="12" lg="12" class="text-center" v-show="items.length == 0">
          <CCarousel 
            arrows
            height="400px"
            controls
            class="mb-5"
          >   
          </CCarousel>
        </CCol>
      </CRow>

      <template #footer>
        <CButton color="wipe" @click="closeModal">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import inspectionValidation from '@/_validations/inspection/inspectionValidation';
import { alertPropertiesHelpers } from '@/_helpers/funciones';

function data() {
  return {
    showModal: false,
    titleModal: '',
    loadingOverlay: false,
    
    imageDamage: {
      VisitCargoInspDamageId: '',
      RouteImage: '',
    },

    items: [],
    VisitCargoInspDamageId: '',
    showButtonDelete: false,
  };
}

function closeModal() {
  this.refreshComponent();
  this.$emit('child-refresh', true);
  this.showModal=false;
}

function refreshComponent() {
  this.imageDamage.VisitCargoInspDamageId = '';
  this.imageDamage.RouteImage = '';
  this.items = '';
  this.titleModal= '';
  this.loadingOverlay= false;
  this.items= [];
  this.VisitCargoInspDamageId = ''; 
}

async function getDamageById(VisitCargoInspDamageId) {
  
  this.loadingOverlay = true;
  let damageData = Array;
  await this.$http.get("VisitCargoInspDamage-by-id", { VisitCargoInspDamageId: VisitCargoInspDamageId })
    .then(response => {
      damageData = [...response.data.data];
      if(damageData[0].FilesJson.length >0){
        let imagesList = Array;
        imagesList = damageData[0].FilesJson;
        let arreglo;
        let i=0;
        arreglo = imagesList.map(imagesList => Object.assign({}, arreglo, {
          Nro: i++,
          PhotoRoute:  `${this.$store.getters["connection/getBase"]}${imagesList.PhotoRoute.replace('Public/', '', null, 'i')}`,
          VisitCargoInspectionPhoto: imagesList.VisitCargoInspectionPhoto,
          PhotoRouteOrigin: imagesList.PhotoRoute,
        }));  
        this.items = arreglo;
      }else{
        this.closeModal();
        throw this.$t('label.noRecordsAvailble');
      }
    }).catch( err => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    this.loadingOverlay = false;
}

async function deleteImage(item){
  this.$swal.fire(
    alertPropertiesHelpers(this, {
      text: `${this.$t('label.deleteImage')}`,
    })
  ).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let res = [];
      let FilesJson = [];

      FilesJson = {
        VisitCargoInspectionPhoto: item.VisitCargoInspectionPhoto,
        FileValue: item.PhotoRouteOrigin,
        Status: 0
      };

      this.$http.put("VisitCargoInspDamageFile-update", FilesJson, { root: 'FilesJson' })
      .then(response => {
        res = [...response.data.data];
        this.items = [];

        this.getDamageById(this.VisitCargoInspDamageId);

        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        
        this.loadingOverlay= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingOverlay= false;
      }); 
    }
  });  
}

export default {
  name: 'modal-list-damage-images',
  components: {
  },
  data,
  props: {
    modal: null,
    edit: Boolean,
  },
  mixins: [General],
  directives: UpperCase,
  validations:inspectionValidation,
  watch: {
    modal: async function () {
      if (this.modal) {
        this.refreshComponent();
        this.titleModal = this.modal.TpCargoViewName+' - '+this.modal.TpDamageAbbrev+': '+this.modal.TpDamageName;
        this.imageDamage.VisitCargoInspDamageId = this.modal.VisitCargoInspDamageId; 
        this.VisitCargoInspDamageId = this.modal.VisitCargoInspDamageId;
        
        if(this.edit && !this.modal.VisitCargoInspectionMasterId){
          this.showButtonDelete = true;
        }if(this.edit && this.modal.VisitCargoInspectionMasterId){
          this.showButtonDelete = false;
        }else if(!this.edit){
          this.showButtonDelete = false;
        }

        this.showModal = true; 
        await this.getDamageById(this.modal.VisitCargoInspDamageId);

        this.$emit('closeModal');
      }
    },
  },
  methods: {
    closeModal,
    refreshComponent,
    getDamageById,
    deleteImage,
  },
  computed: {
   
  },
};
</script>
<style lang="scss" scope>
.w-80 {
  width: 80% !important;
}
.carousel-control-prev, .carousel-control-next {
  width: 5%;
}
.carousel-control-next-icon {
  background-image: url("/img/iconos/NextIcon.svg");
}
.carousel-control-prev-icon {
  background-image: url("/img/iconos/PreviousIcon.svg");
}
</style>
