<template>
  <CRow class="mt-2">
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>
    <modalEditSeals :edit="true" :modal="showModalEditSeals" @closeModal="showModalEditSeals=false" @child-refresh="refrescarTabla=true" />
    <modalEditDamage :modal="ShowModalEditDamage" @closeModal="ShowModalEditDamage=false" @child-refresh="refrescarTablaDamage=true" :TpCargoId="tpCargoId" :VisitCargoId="visitCargoId" :ContainerCode="containerCode"/>
    <modalListDamageImages :edit="true" :modal="ShowModalDamageImageList" @closeModal="ShowModalDamageImageList=false" @child-refresh="refrescarTablaDamage=true" />
    <modalAddDamageImages :modal="ShowModalDamageImageAdd" @closeModal="ShowModalDamageImageAdd=false" @child-refresh="refrescarTablaDamage=true" />
    
    <modalGenerateReportInspection :modal="ShowModalGenerteReportInspection" @closeModal="ShowModalGenerteReportInspection=false" @child-refresh="refrescarTabla=true"/>
    <modalInspection :modal="showModalInspection" @closeModal="showModalInspection=false" @child-refresh="refrescarTabla=true" />
    <modalUploadDocument :modal="showModalUploadDocument" @closeModal="showModalUploadDocument=false" @child-refresh="refrescarTabla=true"/>
    <CCol sm="12" v-if="!isDamage">
      <CTabs variant="tabs"  @update:activeTab="handleTab" :active-tab="0">
        <CTab :title="$t('label.inspections')"  :active="isActiveTab(0)">
        <CRow> 
        <CCol sm="11" lg="12" class="d-flex justify-content-end py-2">
          <CButton
            color="add"
            class="d-flex align-items-center"
            @click="showModalInspection=true"
            v-c-tooltip="{
              content: $t('label.inspection'),
              placement: 'top-end',
            }"
            >
            <CIcon name="cil-playlist-add" />{{$t('label.nueva')}}
          </CButton>
        </CCol>
        <CCol sm="11" lg="12" >
          <div>
            <dataTableExtended
              class="align-center-row-datatable"
              id="tabla_inspeccion"
              :fields="fieldsInspectionList"
              :items="formatedItemsInspection"
              :items-per-page="5"
              :fixed="true"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              :loading="loadingInspection"
              hover
              sorter
            >
            
              <template #loading>
                <loading/>
              </template>
              <template #Detalle="{ item }">
                <td class="text-center">
                    <CButton
                      size="sm"
                      color="add"
                      @click="showModalUploadDocument=item"
                      v-c-tooltip="{
                        content: $t('label.uploadDocuments'),
                        placement: 'top-start',
                      }"
                    >
                      <CIcon name="cil-cloud-upload"/>
                    </CButton>
                    <CButton
                      size="sm"
                      color="watch"
                      class="ml-1"
                      v-if="item.FgInspectionType==1"
                      v-c-tooltip="{
                        content: $t('label.see')+$t('label.details'),
                        placement: 'top',
                      }"
                      @click="activeDamage(item)"
                    >
                      <CIcon name="cil-list" />
                    </CButton>
                    <CButton
                      size="sm"
                      color="watch"
                      class="ml-1"
                      v-if="item.FgInspectionType==0"
                      v-c-tooltip="{
                        content: $t('label.see')+$t('label.details'),
                        placement: 'top',
                      }"
                      @click="showModalEditSeals=item"
                    >
                      <CIcon name="cil-list" />
                    </CButton>
                    <CButton
                      size="sm"
                      color="danger"
                      class="ml-1"
                      v-c-tooltip="{
                        content: $t('label.generateReport'),
                        placement: 'top',
                      }"
                      @click="newReportInspection(item)"
                      v-if="item.VisitCargoInspectionMasterId===''"
                    >
                      <CIcon name="cil-file-pdf" />
                    </CButton>
                </td>
              </template>
            </dataTableExtended>
          </div>
        </CCol>
      </CRow>  
        </CTab>
        <CTab :title="$t('label.navReports')"  :active="isActiveTab(1)">
          <br />
          <div>
            <dataTableExtended
              class="align-center-row-datatable"
              :fields="fieldsInspectionReport"
              :items="itemsInspectionReports"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              :loading="loadingReportList"
              hover
              sorter
            >
              <template #loading>
                <loading/>
              </template>
              <template #Status="{item}">
                <td class="text-center">
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                  </CBadge>
                </td>
              </template>
              <template #Detalle="{ item }">
                <td class="text-center">
                    <CButton
                      size="sm"
                      color="danger"
                      v-c-tooltip="{
                        content: $t('label.see')+' '+$t('label.damageReport'),
                        placement: 'top-start',
                      }"
                      @click="getReportInspection(item)"
                    >
                      <CIcon name="cil-file-pdf" />
                    </CButton>
                    <CButton
                      color="wipe"
                      size="sm"
                      class="ml-1"
                      v-c-tooltip="{
                        content: $t('label.removeDocument'),
                        placement: 'top-start',
                      }"
                      @click="deleteReportInspection(item)"
                      v-if="item.FgActCargoInspection"
                  >
                      <CIcon name="cil-trash" />
                  </CButton>
                </td>
              </template>
            </dataTableExtended>
          </div>  
        </CTab>
      </CTabs>
    </CCol>
    <CCol sm="12" v-if="isDamage">
      <CRow class="mb-3">
    
        <CCol sm="8" lg="8" class="d-flex align-items-center justify-content-start ">
          <CButton
              color="edit"
              class=" mr-2"
              v-c-tooltip="{
                content: $t('label.return'),
                placement: 'top',
              }"
              size="sm"
              @click="activeDamage(false)"
            >
              <CIcon name="cil-chevron-left-alt" />
            </CButton>
            <h6 class="mt-2"> {{ $t('label.inspectionDamage')+': '+containerCode }}</h6>
        </CCol>  
        <CCol sm="4" lg="4" class="d-flex align-items-center justify-content-end ">
          <CButton
            square
            size="sm"
            color="add"
            @click="ShowModalEditDamage=true"
            class="float-right"
            v-c-tooltip="{
              content: $t('label.damage'),
              placement: 'top',
            }"
            v-show="showDamage"
          >
            <CIcon name="cil-playlist-add" />
              <span class="ml-1">
                {{$t('label.nuevo')}}
              </span>
          </CButton>
        </CCol>
      </CRow>
      
      <dataTableExtended
        class="align-center-row-datatable"
        :fields="fieldsDamage"
        :items="itemsDamage"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        :loading="loadingDamage"
        hover
        sorter
      >
        <template #loading>
          <loading/>
        </template>
        <template #Detalle="{ item }">
          <td class="text-center">
              <CButton
                size="sm"
                color="edit"
                v-c-tooltip="{
                  content: $t('label.editDamage'),
                  placement: 'top',
                }"
                @click="getDamageById(item)"
                v-if="!item.VisitCargoInspectionMasterId"
              >
                <CIcon name="pencil" />
              </CButton>
              <CButton
                size="sm"
                color="add"
                class="ml-1"
                v-c-tooltip="{
                  content: $t('label.add')+$t('label.image'),
                  placement: 'top',
                }"
                @click="ShowModalDamageImageAdd=item"
              >
                <CIcon name="photo"/>
              </CButton>
              <CButton
                size="sm"
                color="watch"
                class="mx-1"
                v-c-tooltip="{
                  content: $t('label.see')+$t('label.image'),
                  placement: 'top',
                }"
                @click="ShowModalDamageImageList=item"
                v-if="item.FilesJson.length>0"
              >
                <CIcon name="eye"/>
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ReportesVisitas from '@/_mixins/reportesVisitas';
import { mapState } from 'vuex';
import modalEditSeals from './modal-edit-seals';
import modalEditDamage from './modal-edit-damage';
import modalListDamageImages from './modal-list-damage-images';
import modalAddDamageImages from './modal-add-damage-images';
import modalGenerateReportInspection from './modal-generate-report-inspection';
import modalInspection from './modal-inspection';
import modalUploadDocument from './modal-upload-document';
function data() {
  return {
    itemsInspectionList: [],
    itemsDamage: [],
    itemsInspectionReports: [],
    selectDamage: [],
    isDamage: false,
    loadingOverlay: false,
    loadingInspection: false,
    loadingDamage: false,
    loadingReportList: false,
    showModalEditSeals: false,
    ShowModalEditDamage: false,
    ShowModalDamageImageList: false,
    ShowModalDamageImageAdd: false,
    ShowModalGenerteReportInspection: false,
    showModalInspection: false,
    showModalUploadDocument: false,
    refrescarTabla: false,
    refrescarTablaDamage: false,
    supplieItem: '',
    VisitId: '',
    visitCargoId: '',
    containerCode: '',
    tpCargoId: '',
    showDamage: false,
    VisitCargoInspectionMasterId: '',
    activeTab: 0,
  };
}

function fieldsInspectionList(){
  return [
    { key: 'Detalle', label: '',sorter: false,filter: false,_style: 'width:5%; min-width:135px; text-align:center'},
    { key: 'Nro', label: '#', filter: false,_classes: 'text-center', _style: 'width:3%; text-align:center'},
    { key: 'ContainerCode', label:this.$t('label.containerCode'), _style: ' width:7%', _classes: 'text-uppercase' },
    { key: 'TpInspectionName', label:this.$t('label.inspectionType'), _style: ' width:7%', _classes: 'text-uppercase' },    
    { key: 'TpCargoName', label:this.$t('label.group'), _style: ' width:7%', _classes: 'text-uppercase' },
    { key: 'IsoCode', label:this.$t('label.isoCode'), _style: ' width:6%', _classes: 'text-uppercase' },
    { key: 'ShippingLineCode', label:this.$t('label.carrier'), _style: ' width:7%', _classes: 'text-uppercase' },
    { key: 'TpCargoDetailCode', label:this.$t('label.size')+' (FT)' , _style: ' width:6%', _classes: 'text-uppercase' },
    { key: 'Estatus', label:this.$t('label.status'), _style: ' width:6%', _classes: 'text-uppercase' },
    { key: 'LoadPort', label:'POL', _style: ' width:7%', _classes: 'text-uppercase' },
    { key: 'DischargePort', label:'POD', _style: ' width:7%', _classes: 'text-uppercase' },
    { key: 'MovStowageName', label:this.$t('label.stowageMovement'), _style: ' width:7%', _classes: 'text-uppercase' },
    { key: 'TransactionDate', label:this.$t('label.transactionDate'),  _classes: 'text-uppercase', _style:'width:6%' },
    { key: 'DeviceName', label:this.$t('label.device'), _style: ' width:7%', _classes: 'text-uppercase' },
    { key: 'Ultimo', label: this.$t('label.user'),_style: 'width:6%;', _classes: 'text-uppercase'},
    { key: 'FormatedDate', label: this.$t('label.registerDate'), _style: 'text-align:center; width:6%;', _classes:"text-center"},

  ];
}

function fieldsInspectionReport(){
  return [
    { key: 'Detalle',label: '',sorter: false,filter: false,_style: 'min-width:90px; width:5%;', _classes:"text-center"},
    { key: 'Nro', label: '#', filter: false, _classes: 'text-center', _style: 'width:3%; text-align:center' },
    { key: 'ContainerCode', label:this.$t('label.containerCode'), _style: ' width:7%', _classes: 'text-uppercase' },
    { key: 'InpectionNumberCode', label:this.$t('label.correlative'), _style: ' width:6%', _classes: 'text-uppercase' },
    { key: 'TpInspectionName', label:this.$t('label.inspectionType'), _style: ' width:14%', _classes: 'text-uppercase' },
    { key: 'OperatorName', label:this.$t('label.operator'), _style: ' width:16%', _classes: 'text-uppercase' },
    { key: 'SupervisorName', label:this.$t('label.supervisor'), _style: ' width:16%', _classes: 'text-uppercase' },
    { key: 'InspectionTransactionDate', label: this.$t('label.transactionDate'), _style: 'text-align:center; width:8%;', _classes:"text-center"},
    { key: 'TransaLogin', label: this.$t('label.user'),_style: 'width:8%;', _classes: 'text-uppercase'},
    { key: 'FormatedDate', label: this.$t('label.registerDate'), _style: 'text-align:center; width:8%;', _classes:"text-center"},
    { key: "Status", label: this.$t("label.status"), _style: 'text-align:center; width:8%;', _classes: "text-center" },
  ];
}
      
function fieldsDamage(){
  return [
    { key: 'Nro', label: '#', filter: false, _classes: 'text-center', _style: 'width:3%; text-align:center'  },
    { key: 'TipoDanio', label:this.$t('label.damageType'), _style: 'width:15%;', _classes: 'text-uppercase'},
    { key: 'TpCargoViewName', label:this.$t('label.localizacion'),_style: 'width:12%;', _classes: 'text-uppercase' },
    { key: 'Height', label:this.$t('label.height')+'(CM)',_style: 'width:10%;', _classes: 'text-uppercase' },
    { key: 'Width', label:this.$t('label.width')+'(CM)',_style: 'width:10%;', _classes: 'text-uppercase' },
    { key: 'Dimension', label:this.$t('label.dimensions'),_style: 'width:10%;', _classes: 'text-uppercase' },
    { key: 'InspectionMomentName', label:this.$t('label.inspectionMoment'), _style: 'width:15%;', _classes: 'text-uppercase'},
    { key: 'Responsible', label:this.$t('label.responsible'), _style: 'width:12%;', _classes: 'text-uppercase'},
    { key: 'Detalle',label: '',sorter: false,filter: false,_style: 'min-width:135px; width:12%;  '},
  ];
}

function ConfirmarEliminado(item) {
  this.$swal.fire(
      alertPropertiesHelpers(this, {
        text:  `${this.$t('label.changeStatusQuestion')} ${item.Nro}?`,
      })
    ).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let res = [];
      let VisitSupplyJson = [];

      VisitSupplyJson = {
        VisitId: this.VisitId,
        SupplyId: item.SupplyId,
        UnitMeasureId: item.UnitMeasureId,
        Status: 0
      };

      this.$http.put("VisitSupplyAll-update", VisitSupplyJson, { root: 'VisitSupplyJson' })
      .then(async (response) => {
        res = [...response.data.data];
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        await this.getInspectionList();
        await this.getInspectionReportList(this.Visit);
        this.loadingOverlay= false;    
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.loadingOverlay= false;
      });
    }
  });  
}

function modalSupplie(item) {
  if (item == true) {
    item = Object.assign({VisitId: this.VisitId, item: true });
  } else {
    item = Object.assign({VisitId: this.VisitId, item });
  }
  this.supplieItem = item;
  this.showModal = item;
}

async function getInspectionList() {
  this.loadingInspection = true;
  
  await this.$http.get("VisitCargoInspection-by-VisitId", { VisitId: this.VisitId })
  .then(response => {
    let listado = [...response.data.data];
    let i = 1;
    this.itemsInspectionList =  listado.map((item) => {
        
        return {
            ...item,
            Nro: i++,
        };
    });
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingInspection = false;
  });
  
}

async function activeDamage (valor) {
  //this.loadingOverlay = true;

  this.visitCargoId = valor.VisitCargoId;
  this.containerCode = valor.ContainerCode;
  this.tpCargoId = valor.TpCargoId;
  
  if (!valor) {
    this.itemsDamage = [];
    this.isDamage = valor;
  } else {

  await this.getDamageByVisitCargoId(valor.VisitCargoId);
  this.isDamage = true;
  this.VisitCargoInspectionMasterId = valor.VisitCargoInspectionMasterId;
  }
 // this.loadingOverlay = false;
}

async function getDamageById(item) {
  this.loadingDamage = true;
  let listado = Array;
  await this.$http.get("VisitCargoInspDamage-by-id", { VisitCargoInspDamageId: item.VisitCargoInspDamageId })
    .then(response => {
      listado = [...response.data.data];
    }).catch( err => {
      
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })

  this.ShowModalEditDamage=listado[0];
  this.loadingDamage = false;
}

async function getDamageByVisitCargoId(valor) {
  this.loadingDamage = true;
  let _this = this.$i18n.locale;  
  let listado = Array;
  await this.$http.get("VisitCargoInspDamage-by-VisitCargoId", { VisitCargoId: valor })
    .then(response => {
      listado = [...response.data.data];
      let i = 1;
      this.itemsDamage = listado.map(listado => Object.assign({}, this.itemsDamage, {
        Nro: i++,
        Height: listado.Height ? listado.Height : '',
        InspectionMomentId: listado.InspectionMomentId ? listado.InspectionMomentId : '',
        InspectionMomentName: _this=='en' ? listado.InspectionMomentNameEn : listado.InspectionMomentNameEs,
        Observation: listado.Observation ? listado.Observation: '',
        Responsible: listado.Responsible ? listado.Responsible : '',
        TpCargoViewId: listado.TpCargoViewId ? listado.TpCargoViewId : '',
        TpCargoViewName: listado.TpCargoViewName ? listado.TpCargoViewName : '',
        TpDamageAbbrev: listado.TpDamageAbbrev ? listado.TpDamageAbbrev : '',
        TpDamageId: listado.TpDamageId ? listado.TpDamageId : '',
        TpDamageName: listado.TpDamageName ? listado.TpDamageName : '',
        FormatedDate: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
        VisitCargoInspDamageId: listado.VisitCargoInspDamageId ? listado.VisitCargoInspDamageId : '',
        Width: listado.Width ? listado.Width : '',
        FilesJson: listado.FilesJson ? listado.FilesJson : '',

        TipoDanio: listado.TpDamageAbbrev+":"+listado.TpDamageName,
        TpCargoViewName: listado.TpCargoViewName,
        Dimension: ((listado.Width!=="") && (listado.Height!=="")) ? listado.Width+" x "+listado.Height : "",
        FgActVisitCargoInspDamage: listado.FgActVisitCargoInspDamage ? listado.FgActVisitCargoInspDamage : '',
        VisitCargoInspectionMasterId: listado.VisitCargoInspectionMasterId ? true : false,

      }));
      if(this.VisitCargoInspectionMasterId !== ''){
        this.showDamage = false;
      }
      else{
        this.showDamage = true;
      }

    }).catch( err => {
      this.loadingDamage = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })

 // this.ShowModalEditDamage=listado[0];
    this.loadingDamage = false;
}

async function newReportInspection(item) {
  this.ShowModalGenerteReportInspection=item;
}

async function getInspectionReportList() {
  this.loadingReportList = true;
  this.itemsInspectionReports = [];
  let _this = this.$i18n.locale;   
  
  let listado = Array;
  
  await this.$http.get("VisitCargoInspectionMaster-list", { VisitId: this.VisitId })
  .then(response => {
    listado = [...response.data.data];
   
    let i = 1;
    this.itemsInspectionReports = listado.map(listado => Object.assign({}, this.itemsInspectionReports, {
      Nro: i++,
      ContainerCode: listado.ContainerCode, 
      FgActCargoInspection: listado.FgActCargoInspection ? 1 : 0,
      InpectionNumberCode: listado.InpectionNumberCode ? listado.InpectionNumberCode : '',
      InspectionNumber: listado.InspectionNumber ? listado.InspectionNumber : '',
      InspectionTransactionDate: listado.InspectionTransactionDate ? DateFormater.formatOnlyDateWithSlash(listado.InspectionTransactionDate) : 'N/A',
      OperatorName: listado.OperatorName ? listado.OperatorName : '',
      ReceivedName: listado.ReceivedName ? listado.ReceivedName : '',
      SupervisorName: listado.SupervisorName ? listado .SupervisorName : '',
      TpInspectionId: listado.TpInspectionId ? listado.TpInspectionId : '',
      TpInspectionName: _this=='en' ? listado.TpInspectionNameEn : listado.TpInspectionNameEs,
      TransaLogin: listado.TransaLogin ? listado.TransaLogin : '',
      FormatedDate: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      VisitCargoId: listado.VisitCargoId ? listado.VisitCargoId : '',
      VisitCargoInspectionId: listado.VisitCargoInspectionId ? listado.VisitCargoInspectionId : '',
      VisitCargoInspectionMasterId: listado.VisitCargoInspectionMasterId,
      VisitCargoInspectionStatus: listado.VisitCargoInspectionStatus ? listado.VisitCargoInspectionStatus : '',
      VisitCargoInspectionStatusValue: listado.VisitCargoInspectionStatusValue ? listado.VisitCargoInspectionStatusValue : '',
      Status: _this=='en' ? listado.VisitCargoInspectionStatusValueDsEn : listado.VisitCargoInspectionStatusValueDsEs,
      VisitId: listado.VisitId ? listado.VisitId : '',
      InspectionTransactionDate: listado.InspectionTransactionDate ? DateFormater.formatDateTimeWithSlash(listado.InspectionTransactionDate) : '',
      _classes: listado.FgActCargoInspection === true ? '' : 'table-danger',

    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingReportList = false;
  });
}

async function getReportInspection(item) {
  this.loadingOverlay = true;
  let listado = Array;
  let damageReportData = [];
  let inspectionData = [];
  let _this = this.$i18n.locale;  
  let estatus, InspectionMoment, seals, damage, inspectionType;

  await this.$http.get("VisitCargoDamageReport", { VisitCargoInspectionMasterId: item.VisitCargoInspectionMasterId })
  .then(response => {
    listado = [...response.data.data];
    
    let i = 1;
    let j = 0;
    if (listado.length > 0){
      j = listado[0].DamageDescriptionJson.length;
      seals = listado[0].SealInspectionJson;
      damage = listado[0].DamageDescriptionJson;
      
      if (damage.length > 0){
        InspectionMoment = damage[j-1].InspectionMomentId ? damage[j-1].InspectionMomentId : '';
        inspectionType = true;

        inspectionData = damage.map(damage => Object.assign({}, inspectionData, {
          TpDamage: damage.TpDamageAbbrev+":"+(_this=='en' ? 
            (damage.TpDamageNameEn ? damage.TpDamageNameEn : '') : 
            (damage.TpDamageName ? damage.TpDamageName : '')
          )     ,
          TpCargoViewName: _this=='en' ? 
            (damage.TpCargoViewNameEn ? damage.TpCargoViewNameEn : '') : 
            (damage.TpCargoViewName ? damage.TpCargoViewName : ''),
          Dimension: ((damage.Width!=="") && (damage.Height!=="")) ? damage.Width+" x "+damage.Height : "",
          Responsible: damage.Responsible ? damage.Responsible: '',
          Observation: damage.Observation ? damage.Observation: '',
        }));
      
      } else if(listado[0].SealInspectionJson.length > 0){
        InspectionMoment = listado[0].SealInspectionJson[0].InspectionMomentId ? listado[0].SealInspectionJson[0].InspectionMomentId : '';
        inspectionType = false;
        inspectionData = [
          {
            PreviousSeal: seals[0].Seal1[0].PreviousSeal ? 'S1: '+seals[0].Seal1[0].PreviousSeal : 'S1: ',
            Seal: seals[0].Seal1[0].Seal ? 'S1: '+seals[0].Seal1[0].Seal : '',
            SealConditionName: _this=='en' ?
              (seals[0].Seal1[0].SealConditionNameEn ? 'S1: '+seals[0].Seal1[0].SealConditionNameEn : '') : 
              (seals[0].Seal1[0].SealConditionNameEs ? 'S1: '+seals[0].Seal1[0].SealConditionNameEs : ''),
            ObservationSeal: seals[0].Seal1[0].ObservationSeal ? 'S1: '+seals[0].Seal1[0].ObservationSeal : 'S1: ',
          },
          {
            PreviousSeal: seals[0].Seal2[0].PreviousSeal ? 'S2: '+seals[0].Seal2[0].PreviousSeal : 'S2: ',
            Seal: seals[0].Seal2[0].Seal ? 'S2: '+seals[0].Seal2[0].Seal : 'S2: ',
            SealConditionName: _this=='en' ? 
              (seals[0].Seal2[0].SealConditionNameEn ? 'S2: '+seals[0].Seal2[0].SealConditionNameEn : '') : 
              (seals[0].Seal2[0].SealConditionNameEs ? 'S2: '+seals[0].Seal2[0].SealConditionNameEs : ''),
            ObservationSeal: seals[0].Seal2[0].ObservationSeal ? 'S2: '+seals[0].Seal2[0].ObservationSeal : 'S2: ',
          },
          {
            PreviousSeal: seals[0].Seal3[0].PreviousSeal ? 'S3: '+seals[0].Seal3[0].PreviousSeal : 'S3: ',
            Seal: seals[0].Seal3[0].Seal ? 'S3: '+seals[0].Seal3[0].Seal : 'S3: ',
            SealConditionName: _this=='en' ? 
              (seals[0].Seal3[0].SealConditionNameEn ? 'S3: '+seals[0].Seal3[0].SealConditionNameEn : '') :
              (seals[0].Seal3[0].SealConditionNameEs ? 'S3: '+seals[0].Seal3[0].SealConditionNameEs : ''),
            ObservationSeal: seals[0].Seal3[0].ObservationSeal ? 'S3: '+seals[0].Seal3[0].ObservationSeal : 'S3: ',
          },
          {
            PreviousSeal: seals[0].Seal4[0].PreviousSeal ? 'S4: '+seals[0].Seal4[0].PreviousSeal : 'S4: ',
            Seal: seals[0].Seal4[0].Seal ? 'S4: '+seals[0].Seal4[0].Seal : 'S4: ',
            SealConditionName: _this=='en' ? 
              (seals[0].Seal4[0].SealConditionNameEn ? 'S4: '+seals[0].Seal4[0].SealConditionNameEn : '') :
              (seals[0].Seal4[0].SealConditionNameEs ? 'S4: '+seals[0].Seal4[0].SealConditionNameEs : ''),
            ObservationSeal: seals[0].Seal4[0].ObservationSeal ? 'S4: '+seals[0].Seal4[0].ObservationSeal : 'S4: ',
          },
        ];   
      }   
    
      estatus = item.Estatus ? item.Estatus : '';
      damageReportData = listado.map(listado => Object.assign({}, damageReportData, {
        Nro: i++,
        BaySlot: listado.BaySlot ? listado.BaySlot : '', 
        BerthName: listado.BerthName ? listado.BerthName : '', 
        //BlNo: listado.BlNo ? listado.BlNo : '', 
        BlNo: listado.BlNo ? (listado.BlNo=null ? '' : listado.BlNo) : '',
        ContainerCode: listado.ContainerCode ? listado.ContainerCode : '', 
        DateHourOper: listado.DateHourOper ? listado.DateHourOper : '', 
        DischargePort: listado.DischargePort ? listado.DischargePort : '', 
        LoadPort: listado.LoadPort ? listado.LoadPort : '', 
        Size: listado.Size ? listado.Size : '', 
        TpCargoCode: listado.TpCargoCode ? listado.TpCargoCode : '', 
        TpCargoId: listado.TpCargoId ? listado.TpCargoId : '', 
        VesselNameReport: listado.VesselName ? listado.VesselName : '', 
        VisitCargoId: listado.VisitCargoId ? listado.VisitCargoId : '', 
        Voyage: listado.Voyage ? listado.Voyage : '', 
        OperatorName:  listado.OperatorName ? listado.OperatorName : '', 
        ReceivedName: listado.ReceivedName ? listado.ReceivedName : '',           
        SupervisorName: listado.SupervisorName ? listado.SupervisorName : '',
        Estatus: estatus ? estatus : '',
        InspectionMoment: InspectionMoment,
        MovStowage: listado.MovStowageId ? listado.MovStowageId : '',
        TransacStowage: listado.TransacStowageId ? listado.TransacStowageId : '',
        FgActInspectionMaster: listado.FgActInspectionMaster ? true : false,
      }));
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })

  if(listado.length > 0) {
    await this.getDamageReport(damageReportData[0], inspectionType, item.InpectionNumberCode, item.InspectionTransactionDate, inspectionData);
    this.loadingOverlay = false;
  }else{
    this.loadingOverlay = false;
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: this.$t('label.noRecordsAvailbleReport'),
      type: "error" 
    });

  }
}

async function deleteReportInspection(item) {
  this.$swal.fire(this.alertProperties({
    text: `${this.$t('label.changeStatusQuestion')}?`,
  })).then((result) => {
    if (result.isConfirmed) {
      this.loadingOverlay = true;
      let method = 'PUT';
      let ruta = 'VisitCargoInspectionMaster-deactivate';
      const VisitCargoInsMasterJson = {
        VisitCargoInspectionMasterId: item.VisitCargoInspectionMasterId,
      };

        this.$http
          .ejecutar (method, ruta, VisitCargoInsMasterJson, { root: 'VisitCargoInsMasterJson' })
          .then((response) => {
            if (response.status === (200 || 201)) {
              this.getInspectionReportList(this.Visit);
              this.loadingOverlay = false;
              this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.success')+'!',
                text: response.data.data[0].Response,
                type: "success"
              });
            }
          })
          .catch((e) => {
            this.loadingOverlay = false;
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: e,
              type: "error"
            });
          }).then(() => {
        });
    }
  }); 
}

function handleTab(tab) {
  if (tab == 0) {
    this.getInspectionList();
  }
  if (tab == 1) {
    this.getInspectionReportList(this.Visit);
  }
  this.activeTab = tab;

}

function isActiveTab(current) {
  return this.activeTab == current;
}

  function formatedItemsInspection() {
    let computedItems = [];
    let _this = this;
    if(this.itemsInspectionList.length !== 0){
      this.itemsInspectionList.map(function(item){
        computedItems.push({
          Nro: item.Nro,
          ContainerCode: item.ContainerCode, 
          CargoStatus: _this=='en' ? item.CargoStatusEn : item.CargoStatusEs,
          TpCargoName: _this=='en' ? item.TpCargoNameEn : item.TpCargoNameEs,
          InspectionMomentName: _this=='en' ? item.InspectionMomentNameEn : item.InspectionMomentNameEs,
          IsoCode: item.IsoCode ? item.IsoCode : '', 
          ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '', 
          TpCargoDetailCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
          Estatus: item.TpCargoStatusName ? item.TpCargoStatusName : '',
          LoadPort: item.LoadPort ? item.LoadPort : '',
          DischargePort: item.DischargePort ? item.DischargePort : '',
          MovStowageName: _this=='en' ? item.MovStowageNameEn : item.MovStowageNameEs,
          TpInspectionName: _this=='en' ? item.TpInspectionNameEn : item.TpInspectionNameEs,
          DeviceName: item.DeviceName ? item.DeviceName : '',
          Ultimo: item.TransaLogin?item.TransaLogin:'N/A',
          TransaDate: item.TransactionDate,
          FgInspectionType: item.TpInspectionId=='E27F9D05-9879-43C6-A186-87D707984033' ? 0 : 1,
          SealInspection: item.SealInspectionJson,
          InspectionMomentId: item.InspectionMomentId,
          DamageInspectionJson: item.DamageInspectionJson,
          inspectionType: item.SealInspectionJson == '' ? 0 : 1,
          VisitCargoId: item.VisitCargoId ? item.VisitCargoId : '',
          TpCargoId: item.TpCargoId ? item.TpCargoId : '',
          VisitCargoInspectionId: item.VisitCargoInspectionId ? item.VisitCargoInspectionId : '',
          VisitCargoInspDamageId: item.VisitCargoInspDamageId ? item.VisitCargoInspDamageId : '',
          Size: item.Size ? item.Size : '',
          TpCargoCode: item.TpCargoCode ? item.TpCargoCode : '',
          TpInspectionId: item.TpInspectionId ? item.TpInspectionId : '',
          Status: item.Status ? item.Status : '',
          _classes: item.Status === "INACTIVO" ? 'table-danger': '',
          FgActVisitCargoInspection: item.FgActVisitCargoInspection ? item.FgActVisitCargoInspection: '',
          FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
          TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
          VisitCargoInspectionMasterId: item.VisitCargoInspectionMasterId ? item.VisitCargoInspectionMasterId : '',            
        })
      }) 
    }
    return computedItems;
  }

export default {
  name: 'index-inspeccion',
  data,
  mixins: [GeneralMixin, ReportesVisitas],
  components: {
    modalEditSeals,
    modalEditDamage,
    modalListDamageImages,
    modalAddDamageImages,
    modalGenerateReportInspection,
    modalInspection,
    modalUploadDocument,
  },
  methods: {
    modalSupplie,
    getInspectionList,
    ConfirmarEliminado,
    activeDamage,
    getDamageById,
    getDamageByVisitCargoId,
    newReportInspection,
    getInspectionReportList,
    getReportInspection,
    deleteReportInspection,
    handleTab,
    isActiveTab
  }, 
  props: {
    active: {
      type: Boolean,
      default: false
    },
    currentModuleId: {
      type: Boolean,
      default: true,
    }
  }, 
  watch: {
    active: function(newVal) {
      if(newVal)
        this.activeTab = 0;
    },
    refrescarTabla: async function () {
      if (this.refrescarTabla) {
        await this.getInspectionList();
        await this.getInspectionReportList(this.Visit);
        this.refrescarTabla = false;
      }
    },
    currentModuleId:async function(newValue){
      if ((newValue) && (this.Visit!='')) {
        await this.activeDamage(false);
        this.VisitId = this.Visit;
        await this.getInspectionList();
      }
    },
    refrescarTablaDamage: function () {
      if (this.refrescarTablaDamage) {
        this.showDamage = false;
        this.VisitCargoInspectionMasterId = '';
        this.getDamageByVisitCargoId(this.visitCargoId);
        this.refrescarTablaDamage = false;
      }
    },
  },
  computed: {
    fieldsInspectionList,
    fieldsInspectionReport,
    fieldsDamage,
    formatedItemsInspection,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        tabIndex: state => state.visitas.tabIndex,
        dropItemReport: state => state.visitas.dropItemReport,
    })
  },
};
</script>
