<template>
    <div class="mx-3">
      <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
      <CModalExtended
        :title="titleModal"
        color="dark"
        :show.sync="showModal"
        @update:show="closeModal()"
        :closeOnBackdrop="false"
        size="lg"
      >
          <CRow class="mt-2">
            <CCol sm="12" lg="12">
                <CSelect
                 :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-9'}"
                 :label="$t('label.type')"
                 :options="cancelTransacReasonOptions"
                 addLabelClasses="required text-right"
                 v-model.trim="$v.CancelTransacReasonId.$model"
                 :value.sync="CancelTransacReasonId"
                 :is-valid="hasError($v.CancelTransacReasonId)"
                 :invalid-feedback="errorMessage($v.CancelTransacReasonId)"
                 size="sm"
                 @change="filterType($event)"
                />
            </CCol>
          </CRow>
          <CRow class="mt-2" v-if="CancelTransacReasonId">
            <CCol sm="12" lg="6">
                <CSelect
                 :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                 :label="$t('label.localizacion')"
                 :options="ubicationOptions"
                 addLabelClasses="required text-right"
                 v-model.trim="$v.VesselUbicationId.$model"
                 :value.sync="VesselUbicationId"
                 :is-valid="hasError($v.VesselUbicationId)"
                 :invalid-feedback="errorMessage($v.VesselUbicationId)"
                 size="sm"
                 @change="filterVesselUbication($event)"
                />
            </CCol>
            <CCol sm="12" lg="6" v-if="ValidateCraneTransaction">
                <CSelect
                 :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
                 :label="$t('label.motive')"
                 :options="MovStowageReasonOptions"
                 addLabelClasses="required text-right"
                 v-model.trim="$v.MovStowageReasonId.$model"
                 :value.sync="MovStowageReasonId"
                 :is-valid="hasError($v.MovStowageReasonId)"
                 :invalid-feedback="errorMessage($v.MovStowageReasonId)"
                 size="sm"
                />
            </CCol>
            <CCol sm="12" lg="6" v-if="ValidateCraneTransaction">
                <CSelect
                 :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                 :label="$t('label.crane')"
                 :options="craneOptions"
                 addLabelClasses="required text-right"
                 v-model.trim="$v.VisitCraneId.$model"
                 :value.sync="VisitCraneId"
                 :is-valid="hasError($v.VisitCraneId)"
                 :invalid-feedback="errorMessage($v.VisitCraneId)"
                 size="sm"
                 @change="filterCrane($event)"
                />
            </CCol>
            <CCol sm="12" lg="6"  v-if="ValidateHold || ValidateHoldDeck">
                <CSelect
                 :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-7'}"
                 :label="$t('label.hold')"
                 :options="holdOptions"
                 addLabelClasses="required text-right"
                 v-model.trim="$v.VesselHoldId.$model"
                 :value.sync="VesselHoldId"
                 :is-valid="hasError($v.VesselHoldId)"
                 :invalid-feedback="errorMessage($v.VesselHoldId)"
                 size="sm"
                 @change="filterVesselHatch($event)"
                />
            </CCol>
            <CCol sm="12" lg="6" v-if="ValidateHoldDeck">
                <CSelect
                 :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                 :label="$t('label.hatchCover')"
                 :options="vesselHatchOptions"
                 addLabelClasses="required text-right"
                 v-model.trim="$v.VesselHoldHatchCId.$model"
                 :value.sync="VesselHoldHatchCId"
                 :is-valid="hasError($v.VesselHoldHatchCId)"
                 :invalid-feedback="errorMessage($v.VesselHoldHatchCId)"
                 size="sm"
                />
            </CCol>
          </CRow>
          <CRow class="mt-2">
            <CCol sm="12" lg="12">
                <CTextarea
                addLabelClasses="text-right"
                :label="$t('label.observation')"
                :placeholder="$t('label.observation')"
                :horizontal="{ label: 'col-sm-12 col-lg-2', input: 'col-sm-12 col-lg-9',}"
                rows="3"
                size="sm"
                maxlength="150"
                v-model.trim="$v.Observation.$model"
                :invalid-feedback="errorMessage($v.Observation)"
                :is-valid="hasError($v.Observation)"
                />
            </CCol> 
          </CRow>
  
        <template #footer>
          <CButton outline color="add" :disabled="isSubmit"
            @click.stop="submit()"
          >
            <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
          </CButton>
          <CButton color="wipe" @click="closeModal"  :disabled="isSubmit">
            <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
          </CButton>
        </template>
      </CModalExtended>
    </div>
  </template>
  <script>
  import UpperCase  from '@/_validations/uppercase-directive';
  import ModalMixin from '@/_mixins/modal';
  import mixinGeneral from '@/_mixins/general';
  import mixinServicio from '@/_mixins/servicio';
  import { mapState } from 'vuex';
  import { DateFormater } from '@/_helpers/funciones';
  import containerCancellationValidation from '@/_validations/visitas/containers/containerCancellationValidation';
  import { Money } from "v-money";
  
  function data() {
    return {
      CancelTransacReasonId: '',
      VisitCraneId: '',
      MovStowageReasonId: '',
      VesselUbicationId: '',
      VesselHoldId: '',
      VesselHoldHatchCId: '',
      Observation: '',
   
      measure: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
        maxlength: 18,
      },
  
     
      showModal: false,
      loadingOverlay: false,
      isSubmit: false,
      craneList: [],
      holdList: [],
      restowList: [],
      movStowageReasonList: [],
      VesselHatchList: [],
      ubicationList: [],
      containerMovStowageList: [],
      cancelTransacReasonList: [],
      ContainerData: {},
      VisitCargoId: '',
      VisitId: '',
    };
  }
  
  async function getCraneList() {
    this.loadingOverlay = true;
      return this.$http
        .get('VisitVesselCrane-list?VisitId='+this.Visit)
            .then(async response => {
            this.craneList = response.data.data ?? [];
            await this.getHoldList();
          })
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).finally(() => {
          this.loadingOverlay = false;
        });
  }
  
  
  async function filterCrane(event) { 
    this.VisitCraneId = event.target.value;
    this.VesselHoldId = '';
    this.holdList = [];
    this.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    
    await this.getHoldList();
  }
  
  async function getHoldList() { 
    this.loadingOverlay = true;
    await this.$http.get( 'VisitVesselCraneHold-list', { VisitId: this.Visit, VisitCraneId: this.VisitCraneId })
    .then(response => {
      this.holdList = response.data.data ?? [];
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).finally(() => {
      this.loadingOverlay = false;
    });
  }
  
  function filterVesselHatch(event) {
    this.VesselHoldId = event.target.value;
    this.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    this.getVesselHatch(this.VesselHoldId);
  }

  function getVesselHatch(Id) {
    if(this.VesselHoldId){
      let data = this.holdList.find((val) => val.VesselHoldId == Id);
      this.VesselHatchList = data?.VesselHatchJson ?? [];
    }else{
      this.VesselHoldHatchCId = '';
      this.VesselHatchList = [];
    }
  }
  
  async function getRestowList() { 
    this.loadingOverlay = true;
    await this.$http.get( 'VisitRestowTransacStowage-list')
    .then(response => {
      this.restowList = response.data.data ?? [];
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).finally(() => {
      this.loadingOverlay = false;
    });
  }

  async function getCancelTransacReasonList() { 
    this.loadingOverlay = true;
    await this.$http.get('CancelTransacReason-list?Id='+this.stowageItem.MovStowageId)
    .then(async response => {
      this.cancelTransacReasonList = response.data.data ?? [];
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).finally(() => {
      this.loadingOverlay = false;
    });
  }
 
  async function filterType(event) {
    this.CancelTransacReasonId = event.target.value;
    this.VisitCraneId = '',
    this.MovStowageReasonId ='',
    this.VesselUbicationId = '',
    this.VesselHoldId ='',
    this.VesselHoldHatchCId = '',
    this.holdList = [];
    this.VesselHatchList = [];
    if (this.CancelTransacReasonId) {
      if (this.ValidateCraneTransaction) {
        await this.getListApis();
      }else if(this.ValidateUserError){
        await this.getUbicationList();
      }
    }else{
      this.MovStowageReasonId ='',
      this.VesselUbicationId = '',
      this.VesselHoldId ='',
      this.VesselHoldHatchCId = '',
      this.holdList = [];
      this.VesselHatchList = [];
    }
  }
  
  async function getUbicationList() {
    this.loadingOverlay = true;
    return this.$http
      .get('VesselUbication-list')
        .then(response => {
          let listado = response.data.data;
          if (listado && listado.length != 0){
            this.ubicationList = listado;
          }
        })
        .catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      }).finally(() => {
          this.loadingOverlay = false;
        });
  }
  
  async function filterVesselUbication(event) {
    this.VesselUbicationId = event.target.value;
    this.VisitCraneId = '';
    this.craneList = [];
    this.VesselHoldId = '';
    this.holdList = [];
    this.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    if ((this.ValidateUserError ) && (this.ValidateHold || this.ValidateHoldDeck)) {
      await this.getHoldList();
    }else{
      await this.getCraneList();
    }
  }

  async function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$v.$touch();

      if (this.$v.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;

        throw this.$t('label.errorsPleaseCheck');
      }

      let CargoJson = {
        VisitCraneDetailId: this.stowageItem.VisitCraneDetailId, 
        CancelTransacReasonId: this.CancelTransacReasonId,
        VisitCargoId: this.stowageItem.VisitCargoId,
        VisitCraneId: this.VisitCraneId,
        VesselUbicationId: this.VesselUbicationId,
        VesselHoldId: this.VesselHoldId,
        VesselHoldHatchCId: this.VesselHoldId,
        MovStowageReasonId: this.MovStowageReasonId, 
        MovStowageReasonDs: this.Observation,
        ConfirmationDate: DateFormater.formatDateTimeWithoutSlash(this.stowageItem.TransactionDate),
        Observation: this.Observation, 
      }

      await this.$http
        .ejecutar ('POST', 'VisitGeneralCargoContainer-cancel', CargoJson, { root: 'CargoJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            this.$emit('child-refresh');
            this.closeModal();
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: response.data.data[0].Response,
              type: "success"
            });
          }
        })
        .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      });
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  }
  
  async function getListApis() {
    this.loadingOverlay = true;
    let peticiones =  
      [
        this.$http.ejecutar("GET", "VesselUbication-list"),
        this.$http.ejecutar("GET", 'VisitVesselCrane-list?VisitId='+this.Visit),
        this.$http.ejecutar("GET", 'MovStowageReasonCancel-list',{MovStowageId: this.stowageItem.MovStowageId}),
        this.$http.ejecutar("GET", "VisitVesselCraneHold-list", { VisitId: this.Visit, VisitCraneId: this.VisitCraneId }),
      ];
    await Promise.all(peticiones)
      .then((responses) => {
        this.ubicationList = responses[0].data.data ?? [];
        this.craneList = responses[1].data.data ?? [];
        this.movStowageReasonList = responses[2].data.data ?? [];
        this.holdList = responses[3].data.data ?? [];
       
        this.loadingOverlay = false;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }

  function closeModal() {
    this.refreshComponent();
    this.$emit('closeModal');
  }

  function refreshComponent() {
      this.CancelTransacReasonId = '',
      this.VisitCraneId = '',
      this.MovStowageReasonId = '',
      this.VesselUbicationId = '',
      this.VesselHoldId = '',
      this.VesselHoldHatchCId = '',
      this.Observation = '',
      this.craneList = [],
      this.holdList = [],
      this.restowList = [],
      this.movStowageReasonList = [],
      this.VesselHatchList = [],
      this.ubicationList = [],
      this.containerMovStowageList = [],
      this.cancelTransacReasonList = [],
      this.ContainerData = {},
      this.VisitCargoId = '',
      this.VisitId = '',

      this.$v.$reset();
  }

  
  //computed
  function craneOptions(){
    const chart = [
        {
            value: "",
            label: this.$t('label.select'),
        },
    ];
    this.craneList.map((item) => {
        chart.push({
            label: item.CraneAlias,
            value: item.VisitCraneId,
        });
    });
    return chart;
  }
  
  function holdOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.holdList.map((e) =>{
      chart.push({
        value: e.VesselHoldId,
        label: e.VesselHoldName,
      })
    })
    return chart;
  }
  
  function restowOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.restowList.map((e) =>{
      chart.push({
        value: e.TransacStowageId,
        label: e[`TransacStowageName${_lang}`]
      })
    })
    return chart;
  }

  function cancelTransacReasonOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.cancelTransacReasonList.map((e) =>{
      chart.push({
        value: e.CancelTransacReasonId,
        label: e[`CancelTransacReasonName${_lang}`]
      })
    })
    return chart;
  }
  
  function MovStowageReasonOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.movStowageReasonList.map((e) =>{
      chart.push({
        value: e.MovStowageReasonId,
        label: e[`MovStowageReasonName${_lang}`]
      })
    })
    return chart;
  }
  
  function vesselHatchOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.VesselHatchList.map((e) =>{
      chart.push({
        value: e.VesselHoldHatchCId,
        label: e.HatchCoverName,
      })
    })
    return chart;
  
  }
  
  function directDischargeOptions(){
    return [
        { 
            value: '', 
            label: this.$t('label.select')
        },
        { 
            value: true, 
            label: this.$t('label.yes')
        },
        { 
            value: false, 
            label: 'NO'
        }
    ];
  }
  
  function ubicationOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.ubicationList.map((e) => {
      chart.push({
        value: e.VesselUbicationId,
        label: e[`VesselUbicationName${_lang}`]
      });
    })
    return chart;
  }
  
  function movementStowageOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    const chart = [
        {
          value: "",
          label: this.$t('label.select'),
        },
    ];
      this.containerMovStowageList.map((item) => {
        chart.push({
            label: item[`MovStowageName${_lang}`],
            value: item.MovStowageId,
        });
      });
  
    return chart;
  }
  
  function yardOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.yardList.map((e) => {
      chart.push({
        value: e.YardId,
        label: e.YardName
      });
    })
    return chart;
  }
  
  function titleModal() {
    return `${this.$t('label.reverse')} ${this.$t('label.dischargeConfirmation')}: ${this.stowageItem.ContainerCode}` ;
  }
  
  function ValidateHoldDeck() {
    if (typeof this.VesselUbicationId == 'string') {
      return ((this.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VESSEL_UBICATION_ID_DECK_HOLD));
    }else{
      return false;
    }
  }
  
  function ValidateHold() {
    if (typeof this.VesselUbicationId == 'string') {
      return ((this.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_UBICATION_ID_HOLD));
    }else{
      return false;
    }
  }
  
  function ValidateDeck() {
    if (typeof this.VesselUbicationId == 'string') {
      return ((this.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_UBICATION_ID_DECK));
    }else{
      return false;
    }
  }

  function ValidateCraneTransaction() {
    if (typeof this.CancelTransacReasonId == 'string') {
      let data = this.cancelTransacReasonList.find(item => item.CancelTransacReasonId == this.CancelTransacReasonId);
      return data?.FgApplyCrane ? true : false;
    }else{
      return false;
    }
  }
  
  function ValidateUserError() {
    if (typeof this.CancelTransacReasonId == 'string') {
      let data = this.cancelTransacReasonList.find(item => item.CancelTransacReasonId == this.CancelTransacReasonId);
      return data?.FgApplyCrane ? false : true;
    }else{
      return false;
    }
  }
 
  export default {
    name: 'modal-cancellation',
    data,
    props: { modal: Boolean, stowageItem: Object, isEdit: Boolean },
    components: { Money },
    mixins: [ModalMixin, mixinGeneral, mixinServicio],
    directives: UpperCase,
    validations(){
      return containerCancellationValidation(this.ValidateHoldDeck,this.ValidateHold, this.ValidateUserError);
    },
  
    watch: {
      modal: async function (val) {
        this.showModal = val;
        if(val){
          await this.getCancelTransacReasonList();
        }
      },
    },
    methods: {
      getCraneList,
      filterCrane,
      filterVesselHatch,
      filterVesselUbication,
      getVesselHatch,
      getHoldList,
      getRestowList,
      getCancelTransacReasonList,
      filterType,
      getUbicationList,
      submit,
      getListApis,
      closeModal,
      refreshComponent,
    },
    computed: {
      craneOptions,
      holdOptions,
      restowOptions,
      cancelTransacReasonOptions,
      MovStowageReasonOptions,
      vesselHatchOptions,
      directDischargeOptions,
      ubicationOptions,
      movementStowageOptions,
      yardOptions,
      titleModal,
      ValidateHoldDeck,
      ValidateHold,
      ValidateDeck,
      ValidateCraneTransaction,
      ValidateUserError,
      ...mapState({
        Visit: state => state.visitas.VisitId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        branch: state => state.auth.branch,
      }),
    },
  };
  </script>
  <style lang="scss">
  .status-open {
     .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
      border-color: #42aa91 !important;
    }
  
  }
  .status-close {
     .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
      border-color: #FF8000 !important;
    }
    .form-control:focus {
      border-color: #FF8000 !important;
      -webkit-box-shadow: 0 0 0 0.1rem #FF8000 !important;
      box-shadow: 0 0 0 0.1rem #FF8000 !important;
    }
  }
  .status-inactive {
     .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
      border-color: #e1373f !important;
    }
    .form-control:focus {
      border-color: #e1373f !important;
      -webkit-box-shadow: 0 0 0 0.1rem #e1373f !important;
      box-shadow: 0 0 0 0.1rem #e1373f !important;
    }
  }
  .status-EndOperation {
    .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
      border-color: #1A3760 !important;
    }
    .form-control:focus {
      border-color: #1A3760 !important;
      -webkit-box-shadow: 0 0 0 0.1rem #1A3760 !important;
      box-shadow: 0 0 0 0.1rem #1A3760 !important;
    }
  }
  
  .condition-label label:before {
      color: #e55353;
      content: "* ";
      display: inline;
  }
  </style>
  