<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal()"
      :closeOnBackdrop="false"
      size="xl"
    >
        <CRow>
          <CCol>
            <CRow>
              <!-- General cargo confirmation-->
              <CCol sm="12" lg="6">
                <h6 class="text-bold">{{$t('label.dischargeConfirmation')}}</h6>
                <CRow>
                  <CCol sm="12" lg="6" class="d-flex flex-wrap align-items-center">
                    <CCol sm="12"class="px-0">
                      <div class="form-group form-row" rol="group">
                        <label class="col-form-label-sm text-right required col-sm-12 col-lg-4 mb-0 requiered">
                          {{$t('label.date')}}
                        </label>
                        <div class="col-sm-12 col-lg-8 input-group">
                          <vue-datepicker
                            type="datetime"
                            header
                            :lang="this.$i18n.locale"
                            :editable="false"
                            :clearable="false"
                            format="DD/MM/YYYY HH:mm"
                            placeholder="DD/MM/YYYY HH:mm"
                            time-title-format="DD/MM/YYYY HH:mm"
                            v-model="generalCargo.TransactionDate"
                            :disabled-date="validateDateRange"
                            :append-to-body="false"
                            value-type="format"
                            :show-second="false"
                            @input="ValidateDate()"
                          >
                              <template #input>
                                <CInput
                                  class="w-100 float-left mb-0"
                                  :value="generalCargo.TransactionDate"
                                  placeholder="DD/MM/YYYY HH:mm"
                                  :is-valid="hasError($v.generalCargo.TransactionDate)"
                                  @blur="$v.generalCargo.TransactionDate.$touch()"
                                  size="sm"
                                  @input="ValidateDate()"
                                >
                                  <template #append-content>
                                    <CIcon name="cil-calendar" />
                                  </template>
                                </CInput>
                              </template>
                              <template #icon-calendar>
                                <div style="display: none"></div>
                              </template>
                            </vue-datepicker>
                        </div>
                      </div>
                    </CCol>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8 d-flex flex-wrap align-items-center'}"
                      :label="$t('label.movementType')"
                      :options="movementStowageOptions"
                      addLabelClasses="required text-right"
                      v-model.trim="$v.generalCargo.MovStowageId.$model"
                      :value.sync="generalCargo.MovStowageId"
                      :is-valid="hasError($v.generalCargo.MovStowageId)"
                      :invalid-feedback="errorMessage($v.generalCargo.MovStowageId)"
                      size="sm"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :label="$t('label.location')"
                      :options="ubicationOptions"
                      addLabelClasses="required text-right"
                      v-model.trim="$v.generalCargo.VesselUbicationId.$model"
                      :value.sync="generalCargo.VesselUbicationId"
                      :is-valid="hasError($v.generalCargo.VesselUbicationId)"
                      :invalid-feedback="errorMessage($v.generalCargo.VesselUbicationId)"
                      size="sm"
                      @blur="$v.generalCargo.VesselUbicationId.$touch()"
                      @change="filterVesselUbication($event)"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :label="$t('label.crane')"
                      addLabelClasses="required text-right"
                      :options="craneOptions"
                      v-model.trim="$v.generalCargo.VisitCraneId.$model"
                      :value.sync="generalCargo.VisitCraneId"
                      :is-valid="hasError($v.generalCargo.VisitCraneId)"
                      :invalid-feedback="errorMessage($v.generalCargo.VisitCraneId)"
                      size="sm"
                      @blur="$v.generalCargo.VisitCraneId.$touch()"
                      @change="filterCrane($event)"
                    />
                  </CCol>
                  <CCol sm="12" lg="6" v-if="ValidateHold || ValidateHoldDeck">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :label="$t('label.hold')"
                      addLabelClasses="required text-right"
                      :options="holdOptions"
                      v-model.trim="$v.generalCargo.VesselHoldId.$model"
                      :value.sync="generalCargo.VesselHoldId"
                      :is-valid="hasError($v.generalCargo.VesselHoldId)"
                      :invalid-feedback="errorMessage($v.generalCargo.VesselHoldId)"
                      size="sm"
                      @blur="$v.generalCargo.VesselHoldId.$touch()"
                      @change="filterVesselHatch($event)"
                    />
                  </CCol>
                  <CCol sm="12" lg="6" v-if="ValidateHoldDeck">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :label="$t('label.hatchCover')"
                      addLabelClasses="required text-right"
                      :options="vesselHatchOptions"
                      v-model.trim="$v.generalCargo.VesselHoldHatchCId.$model"
                      :value.sync="generalCargo.VesselHoldHatchCId"
                      :is-valid="hasError($v.generalCargo.VesselHoldHatchCId)"
                      :invalid-feedback="errorMessage($v.generalCargo.VesselHoldHatchCId)"
                      size="sm"
                      @blur="$v.generalCargo.VesselHoldHatchCId.$touch()"
                    />
                  </CCol>
                  <CCol sm="12" lg="6" v-if="isEdit">
                    <CSelect
                      :label="$t('label.status')"
                      addLabelClasses="text-right required"
                      :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                      :value="generalCargo.Status"
                      :is-valid="statusSelectColor"
                      v-model.trim="generalCargo.Status"
                      :options="statusOptions"
                      size="sm"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CTextarea
                      :horizontal="{label:'text-right col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :label="$t('label.observation')"
                      addLabelClasses="text-right"
                      v-uppercase
                      v-model.trim="$v.generalCargo.Observation.$model"
                      :value.sync="generalCargo.Observation"
                      :is-valid="hasError($v.generalCargo.Observation)"
                      :invalid-feedback="errorMessage($v.generalCargo.Observation)"
                      size="sm"
                      maxlength="150"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="12" lg="6">
                <h6 class="text-bold">{{$t('label.PackagingBlInformation')}}</h6>
                <CRow>
                  <CCol sm="12">
                    <CRow>
                      <CCol sm="12" lg="6">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :label="$t('label.detail')"
                          :options="packagingOptions"
                          addLabelClasses="text-right required"
                          v-model.trim="$v.PackagingId.$model"
                          :value.sync="$v.PackagingId.$model"
                          :is-valid="hasError($v.PackagingId)"
                          :invalid-feedback="errorMessage($v.PackagingId)"
                          :disabled="isEditPackaging"
                          size="sm"
                          @change="filterDetail($event)"
                        />
                      </CCol>
                      <CCol sm="12" lg="6">
                        <div class="form-group form-row">
                          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm required m-0">{{$t('label.quantity')}}</label>
                          <div class="input-group col-sm-12 col-lg-7 input-group-sm align-items-center">
                            <money
                              size="sm"
                              v-bind="Quantitymeasure" 
                              :class="!$v.Quantity.$dirty ? 'form-control' : ($v.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                              v-model="$v.Quantity.$model"
                              addLabelClasses="required text-right"
                              maxlength= "13"
                              @change="filterQuantity($event)"
                              :disabled ="PackagingId ? false : true"
                            >
                            </money>
                            <div class="invalid-feedback" v-if="$v.Quantity.$dirty && $v.Quantity.$error" >
                              {{ errorMessage($v.Quantity) }}
                            </div>
                          </div>
                        </div>  
                      </CCol>
                      <CCol v-if="PackagingId" sm="12" lg="6">
                        <CSelect
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7 d-flex align-items-center'}"
                          :label="$t('label.bl')"
                          :options="BlOptions"
                          addLabelClasses="text-right"
                          v-model.trim="$v.VisitBillOfLadingPackagingId.$model"
                          :is-valid="hasError($v.VisitBillOfLadingPackagingId)"
                          :invalid-feedback="errorMessage($v.VisitBillOfLadingPackagingId)"
                          :disabled="isEditPackaging"
                          size="sm"
                          @change="filterBl($event.target.value)"
                        />
                      </CCol>
                      <CCol v-if="PackagingId" sm="12" lg="6">
                        <CInput
                          size="sm"
                          v-uppercase
                          v-model="$v.ClientName.$model"
                          :label="$t('label.client')"
                          addLabelClasses="text-right"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                          :placeholder="$t('label.clientName')"
                          :is-valid="hasError($v.ClientName)"
                          :invalid-feedback="errorMessage($v.ClientName)"
                          disabled
                        />
                      </CCol>
                      
                      <CCol sm="12" lg="6">
                        <div class="form-group form-row">
                          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm m-0">{{$t('label.plannedQuantity')}}</label>
                          <div class="input-group col-sm-12 col-lg-7 input-group-sm align-items-center">
                            <money
                              size="sm"
                              v-bind="measure" 
                              :class="!$v.QuantityPlanning.$dirty ? 'form-control' : ($v.QuantityPlanning.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                              v-model="QuantityPlanning"
                              addLabelClasses="required text-right"
                              maxlength= "13"
                              disabled
                            >
                            </money>
                            <div class="invalid-feedback" v-if="$v.QuantityPlanning.$error">
                              {{ errorMessage($v.QuantityPlanning) }}
                            </div>
                          </div>
                        </div> 
                      </CCol>
                      <CCol sm="12" lg="6">
                        <div class="form-group form-row">
                          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm m-0">{{$t('label.dischargeQuantity')}}</label>
                          <div class="input-group col-sm-12 col-lg-7 input-group-sm align-items-center">
                            <money
                              size="sm"
                              v-bind="measure" 
                              :class="!$v.QuantityStowageRate.$dirty ? 'form-control' : ($v.QuantityStowageRate.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                              addLabelClasses="required text-right"
                              maxlength= "13" 
                              :value.sync="calculateQuantityStowage"
                              disabled
                            >
                            </money>
                            <div class="invalid-feedback" v-if="$v.QuantityStowageRate.$error">
                              {{ errorMessage($v.QuantityStowageRate) }}
                            </div>
                          </div>
                        </div> 
                      </CCol>
                      <CCol sm="12" lg="6">
                        <div class="form-group form-row">
                          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm m-0">{{$t('label.remainder')}}</label>
                          <div class="input-group col-sm-12 col-lg-7 input-group-sm align-items-center">
                            <money
                              size="sm"
                              v-bind="measure" 
                              :class="!$v.ValidateQuantity.$dirty ? 'form-control' : ($v.ValidateQuantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                              addLabelClasses="required text-right"
                              maxlength= "13"
                              :value.sync="calculateQuantityRemainder"
                              disabled
                            >
                            </money>
                            <div class="invalid-feedback" v-if="$v.ValidateQuantity.$error">
                              {{ errorMessage($v.ValidateQuantity) }}
                            </div>
                          </div>
                        </div> 
                      </CCol>
                    </CRow>    
                  </CCol>
                  <CCol sm="12">
                    <CCol class="d-flex justify-content-end col-form-label p-0 col-sm-12 col-lg-12 col-form-label-sm mb-2">
                      <CButton
                          color="add"
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}"
                          @click="SubmitPackaging"
                          >
                          <CIcon name="checkAlt"/>
                      </CButton>
                      <CButton
                          color="wipe"
                          class="mr-1"
                          size="sm"
                          v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}"
                          @click="clearFilters()"
                          >
                          <CIcon name="cil-brush-alt" />
                      </CButton>
                    </CCol>
                  </CCol>
                </CRow>
              </CCol>
                
             

              <CCol sm="12" lg="12" class="justify-content-center">
                <dataTableExtended
                  class="align-center-row-datatable"
                  :items-per-page="5"
                  :items="ComputedPackaging"
                  :fields="Packagingfields"
                  column-filter
                  pagination
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :noItemsView="tableText.noItemsViewText"
                  sorter
                >

                    <template #Status="{ item }">
                      <td class="text-center">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                      </td>
                    </template>
                    <template #options="{ item }">
                      <td class="text-center">
                        <CButton
                          color="edit"
                          square
                          size="sm"
                          class="mr-2"
                          v-c-tooltip="{
                            content: $t('label.edit')+' '+$t('label.packaging'),
                            placement: 'top-end'
                            }"
                          @click="EditPackaging(item)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                        <CButton
                          class="btn btn-sm btn-wipe"
                          v-c-tooltip="{
                            content: $t('label.delete')+$t('label.detail'),
                            placement: 'top-end',
                          }"
                          @click="DeletePackaging(item)"
                        >
                          <CIcon name="cil-trash" />
                        </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                  <p v-if="PackagingJsonList.filter(e => e.Status != 0).length === 0" class="mt-2 text-danger text-center required">
                    {{$t('label.must')+' '+$t('label.aggregate')+' '+$t('label.atLeast')+' 1 '+$t('label.detail')}}
                  </p>
                  <p class="text-right fw-bold">{{ `TOTAL: ${FormatNumber(PackagingJsonList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0), 2)}` }}</p>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

      <template #footer>
        <CButton outline color="add" :disabled="isSubmit"
          @click.stop="isEdit ? statusConfirmation(stowageItem.FgActVisitCraneDetail,generalCargo.Status, submit) : submit()"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal"  :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import stowageValidation from '@/_validations/visitas/general-cargo/stowageValidation';
import { Money } from "v-money";

function data() {
  return {
    VisitCraneDetailPackagingId: '',
    VisitCraneDetailId: '',
    generalCargo: {
      TransactionDate: '',
      VisitCraneId: '',
      VesselHoldId: '',
      VesselHoldHatchCId: '',
      Observation: '',
      MovStowageId: '',
      VesselUbicationId: '',
      Status: 0,
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    Quantitymeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 16,
    },

    VisitBillOfLadingPackagingId: '',
    ClientName: '',
    PackagingId: '',
    Quantity: 0,
    QuantityPlanning: 0,
    QuantityStowageRate: 0,
    ValidateQuantity: 0,
    previousDate: new Date(),
    laterDate: new Date(),
    isEditPackaging: false,
    CleanInformation: false,
    ValidPreviousDate: '',
    ValidLaterDate: '',

    showModal: false,
    loadingOverlay: false,
    isSubmit: false,
    craneList: [],
    holdList: [],
    VesselHatchList: [],
    packagingList: [],
    ubicationList: [],
    generalCargoMovStowageList: [],
    PackagingJsonList: [],
    BlList: [],
    VisitCargoId: '',
    VisitId: '',
  };
}

  function closeModal() {
    this.refreshComponent();
    this.$emit('closeModal');
  }

  function refreshComponent() {
    //GeneralCargo
    this.generalCargo.TransactionDate = '';
    this.generalCargo.VesselUbicationId = '';
    this.generalCargo.VisitCraneId = '';
    this.generalCargo.VesselHoldId = '';
    this.generalCargo.VesselHoldHatchCId = '';
    this.generalCargo.Observation = '';
    this.generalCargo.MovStowageId = '';
    this.generalCargo.Status = 0;
    this.PackagingId = '';
    this.Quantity = 0;
    this.QuantityPlanning = 0;
    this.QuantityStowageRate = 0;
    this.ValidateQuantity = 0;
    this.isEditPackaging = false;
    this.ClientName = '';
    this.VisitBillOfLadingPackagingId = '';
    
    //Arrys
    this.craneList = [];
    this.holdList = [];
    this.VesselHatchList = [];
    this.generalCargoMovStowageList = [];
    this.BlList = [];
    this.PackagingJsonList = [];
    this.VisitCargoId = '';
    this.VisitCraneDetailPackagingId = '';

    setTimeout(() => {
      this.$v.$reset();
    }, 10);
  }

  async function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$v.generalCargo.$touch();

      if (this.$v.generalCargo.$error  || this.PackagingJsonList.filter(e => e.Status != 0).length === 0 ) 
      {
        this.loadingOverlay = false;
        this.isSubmit = false;

        if(DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined){
          throw this.$t('label.ErrorAtaPleaseCheck');
        }

        if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
          throw this.$t('label.ErrorAtdPleaseCheck');
        }
        if(this.generalCargo.TransactionDate != ''){
          if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
        
        throw this.$t('label.errorsPleaseCheck');
      }

      let QuantityAccumulator = this.PackagingJsonList.filter(e => e.Status != 0).reduce((accumulator, currentValue) => accumulator += currentValue?.Quantity, 0);

      let Quantity = Number(QuantityAccumulator);
     
       let GeneralCargoJson = this.isEdit ? {
          VisitCraneDetailId: this.VisitCraneDetailId,
          VisitId: this.Visit,
          VesselHoldId: this.generalCargo.VesselHoldId,
          VesselUbicationId: this.generalCargo.VesselUbicationId,
          VesselHoldHatchCId: this.generalCargo.VesselHoldHatchCId,
          MovStowageId:this.generalCargo.MovStowageId,
          VisitCraneId: this.generalCargo.VisitCraneId,
          TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate),
          PackagingJson: this.PackagingJsonList.map((e) =>{
            return {
              VisitBillOfLadingPackagingId: e.VisitBillOfLadingPackagingId ?? '',
              VisitCraneDetailPackagingId: e.VisitCraneDetailPackagingId ?? '',
              PackagingId: e.PackagingId ?? '',
              Quantity: e.Quantity ?? 0,
              Status: e.Status,
            }
          }),
          Observation: this.generalCargo.Observation,
          Quantity: Quantity,
          Status: this.generalCargo.Status
        } : {
          VisitId: this.Visit,
          VisitCraneId: this.generalCargo.VisitCraneId,
          TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate),
          VesselHoldId: this.generalCargo.VesselHoldId,
          VesselUbicationId: this.generalCargo.VesselUbicationId,
          VesselHoldHatchCId: this.generalCargo.VesselHoldHatchCId,
          PackagingJson: this.PackagingJsonList.map((e) =>{
            return {
              VisitBillOfLadingPackagingId: e.VisitBillOfLadingPackagingId ?? '',
              VisitCraneDetailPackagingId: e.VisitCraneDetailPackagingId ?? '',
              PackagingId: e.PackagingId ?? '',
              Quantity: e.Quantity ?? 0,
              Status: e.Status,
            }
          }),
          Quantity: Quantity,
          Observation: this.generalCargo.Observation,
          MovStowageId:this.generalCargo.MovStowageId,
        }
      
      let ruta = this.isEdit ? 'VisitGeneralCargoDischargeConfirmation-update' : 'VisitGeneralCargoDischargeConfirmation';
      let method = this.isEdit ? 'PUT':'POST';

      await this.$http
        .ejecutar (method, ruta, GeneralCargoJson, { root: 'GeneralCargoJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            this.$emit('child-refresh');
            this.closeModal();
            this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: response.data.data[0].Response,
              type: "success"
            });
            this.loadingOverlay = false;
          }
        })
        .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      });
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  }

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  async function getDateRange(){
    let currentDate = new Date();
    if(this.itinerarySelected.Ata!=undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
    }

    if(this.itinerarySelected.Ata==undefined){
      this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta));
    }

    if(this.itinerarySelected.Atd!=undefined){
      this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
      this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd));
    }

    if(this.itinerarySelected.Atd==undefined){
      this.laterDate = currentDate;
      this.ValidLaterDate = DateFormater.formatTimeZoneToDateTimewithDash(this.laterDate);
    }
  }

  async function getCraneList() {
    this.loadingOverlay = true;
      await this.$http
        .get('VisitVesselCrane-list?VisitId='+this.Visit)
           .then(async response => {
            this.craneList = response.data.data ?? [];
            await this.getHoldList();
          })
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).finally(() => {
          this.loadingOverlay = false;
        });
  }


  async function filterCrane(event) { 
    this.generalCargo.VisitCraneId = event.target.value;
    this.generalCargo.VesselHoldId = '';
    this.holdList = [];
    this.generalCargo.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
   
    await this.getHoldList();
  }

  async function getHoldList() { 
    if (this.generalCargo.VisitCraneId != '') {
      this.loadingOverlay = true;
      await this.$http.get( 'VisitVesselCraneHold-list', { VisitId: this.Visit, VisitCraneId: this.generalCargo.VisitCraneId })
      .then(response => {
        this.holdList = response.data.data ?? [];
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).finally(() => {
        this.loadingOverlay = false;
      });
    }
    else{
      this.generalCargo.VesselHoldId = '';
      this.holdList = [];
      this.generalCargo.VesselHoldHatchCId = '';
      this.VesselHatchList = [];
    }  
  }

  function filterVesselHatch(event) {
    this.generalCargo.VesselHoldId = event.target.value;
    this.generalCargo.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    this.getVesselHatch(this.generalCargo.VesselHoldId);
  }

  function getVesselHatch(Id) {
    if(this.generalCargo.VesselHoldId ){
      let data = this.holdList.find((val) => val.VesselHoldId == Id);
      this.VesselHatchList = data?.VesselHatchJson ?? [];
    }else{
      this.generalCargo.VesselHoldHatchCId = '';
      this.VesselHatchList = [];
    }
  }

  async function getMovementStowageList() {
      await this.$http
      .get(`VisitMovStowageGeneralCargo-list?VisitId=${this.Visit}&VisitCargoId=${this.VisitCargoId}`)
      .then(response => {
        let listado = response.data.data && response.data.data[0] ? response.data.data[0] : {};
        if (Object.keys(listado).length != 0){
          this.generalCargoMovStowageList = listado?.GeneralCargoMovStowageJsonList ?? [];
        }
      }).catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      })
  }

  async function getPackagingList() {
    await this.$http
      .get('VisitGeneralCargoImportPackaging-list?VisitId='+this.Visit)
        .then(response => {
          this.packagingList = response.data.data ?? [];
        }).catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      })
  }

  async function getUbicationList() {
    await this.$http
      .get('VesselUbication-list')
        .then(response => {
          let listado = response.data.data;
          if (listado && listado.length != 0){
            this.ubicationList = listado;
          }
        })
        .catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
      })
  }

  async function filterVesselUbication(event) {
    this.generalCargo.VesselUbicationId = event.target.value;
    this.generalCargo.VisitCraneId = '';
    this.craneList = [];
    this.generalCargo.VesselHoldId = '';
    this.holdList = [];
    this.generalCargo.VesselHoldHatchCId = '';
    this.VesselHatchList = [];
    await this.getCraneList();
  }

  function filterDetail(event) {
    this.PackagingId = event.target.value;
    this.QuantityPlanning = 0;
    this.QuantityStowageRate = 0;
    this.Quantity = 0;
    this.ValidateQuantity = 0;
    this.isEditPackaging = false;
    this.BlList = [];
    this.ClientName = '';
    this.VisitBillOfLadingPackagingId = '';
    this.getQuantityData(this.PackagingId);
  }

  function filterQuantity(event) {
    this.Quantity = event.target.value;
    this.ValidateQuantity += this.Quantity;
  }

  function filterBl(id) {
    this.VisitBillOfLadingPackagingId = id;
    this.ClientName = '';
    let PackagingJsonFilter = [];
    if (this.VisitBillOfLadingPackagingId) {
      let data = this.BlList.find((val) => val.VisitBillOfLadingPackagingId == this.VisitBillOfLadingPackagingId);
      this.QuantityPlanning = data?.QuantityPlanning ?? 0;
      this.QuantityStowageRate = data?.QuantityStowageRate && this.isEdit ? ((data?.QuantityStowageRate ?? 0) - (this.isEditPackaging ? this.Quantity : 0)- (this.stowageItem?.PackagingJson?.filter(item => item.PackagingId == this.PackagingId && (item?.VisitBlPackagingInfoJson?.[0]?.VisitBillOfLadingPackagingId ?? '') == this.VisitBillOfLadingPackagingId)?.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0) ?? 0)) : (data?.QuantityStowageRate ?? 0);
      this.ClientName = data?.ClientName ?? '';
      PackagingJsonFilter = this.PackagingJsonList.filter(item => item.PackagingId == this.PackagingId && (this.VisitBillOfLadingPackagingId ? item.VisitBillOfLadingPackagingId == this.VisitBillOfLadingPackagingId : true) && item.Status != 0);
      if(PackagingJsonFilter.length != 0) {
        this.QuantityStowageRate += PackagingJsonFilter.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0);
      }
      this.ValidateQuantity = Number((this.QuantityPlanning - this.QuantityStowageRate).toFixed(2));
    }else {
      let data = this.packagingList.find((val) => val.PackagingId == this.PackagingId);
      this.QuantityPlanning = data?.QuantityPlanning ?? 0;
      this.QuantityStowageRate = data?.QuantityStowageRate && this.isEdit ? ((data?.QuantityStowageRate ?? 0) - (this.stowageItem?.PackagingJson?.filter(item => item.PackagingId == this.PackagingId)?.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0) ?? 0)) : (data?.QuantityStowageRate ?? 0);
      PackagingJsonFilter = this.PackagingJsonList.filter(item => item.PackagingId == this.PackagingId && item.Status != 0)
      if(PackagingJsonFilter.length != 0) {
        this.QuantityStowageRate += PackagingJsonFilter.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0)
      }
      this.ValidateQuantity = Number((this.QuantityPlanning - this.QuantityStowageRate).toFixed(2));
    }
  }

  async function getQuantityData(Id, isNew = true) {
    if(this.PackagingId){
      let PackagingJsonFilter = [];
      let data = this.packagingList.find((val) => val.PackagingId == Id);
      this.BlList = data?.VisitBlPackagingInfoJson ?? [];

      if (isNew) {
        this.ClientName = this.BlList.length == 1 ? this.BlList[0].ClientName : '';
        this.VisitBillOfLadingPackagingId = this.BlList.length == 1 ? this.BlList?.[0]?.VisitBillOfLadingPackagingId : '';
      }

      if(!this.VisitBillOfLadingPackagingId){
        this.QuantityPlanning = data?.QuantityPlanning ?? 0;
        this.QuantityStowageRate = data?.QuantityStowageRate && this.isEdit ? ((data?.QuantityStowageRate ?? 0) - this.Quantity - (this.stowageItem?.PackagingJson?.filter(item => item.PackagingId == this.PackagingId && (this.isEditPackaging ? item.VisitBillOfLadingPackagingId : true))?.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0) ?? 0)) : (data?.QuantityStowageRate ?? 0);
        PackagingJsonFilter = this.PackagingJsonList.filter(item => item.PackagingId == this.PackagingId && (this.isEditPackaging ? item.VisitBillOfLadingPackagingId : true) && item.Status != 0);
        if(PackagingJsonFilter.length != 0) {
          this.QuantityStowageRate += PackagingJsonFilter.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0);
        }
        this.ValidateQuantity = Number((this.QuantityPlanning - this.QuantityStowageRate).toFixed(2));
      }else{
        await this.filterBl(this.VisitBillOfLadingPackagingId)
      }
    }else{
      this.BlList = [];
      this.ClientName = '';
      this.VisitBillOfLadingPackagingId = '';
      this.QuantityPlanning = 0;
      this.QuantityStowageRate = 0;
    }
  }

  async function getListApis() {
    this.loadingOverlay = true;
    let peticiones =  
      [
        this.$http.ejecutar("GET", "VesselUbication-list"),
        this.$http.ejecutar("GET", 'VisitVesselCrane-list?VisitId='+this.Visit),
        this.$http.ejecutar("GET", `VisitMovStowageGeneralCargo-list?VisitId=${this.Visit}&VisitCargoId=${this.VisitCargoId}`),
        this.$http.ejecutar("GET", 'VisitGeneralCargoImportPackaging-list?VisitId='+this.Visit),
        this.$http.ejecutar("GET", "VisitVesselCraneHold-list", { VisitId: this.Visit, VisitCraneId: this.generalCargo.VisitCraneId }),
      ];
    await Promise.all(peticiones)
      .then((responses) => {

       
        this.ubicationList = responses[0].data.data;
        this.craneList = responses[1].data.data;
        let listado = responses[2].data.data && responses[2].data.data[0] ? responses[2].data.data[0] : {};
        if (Object.keys(listado).length != 0){
          this.generalCargoMovStowageList = listado?.GeneralCargoMovStowageJsonList ?? [];
        }
        this.packagingList = responses[3].data.data;
        if(this.isEdit){
          this.holdList = responses[4].data.data;
        }else{
          this.holdList = [];
        }
        this.loadingOverlay = false;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }

  function SubmitPackaging(){
    try {
      this.$v.PackagingId.$touch();
      this.$v.Quantity.$touch();
      this.$v.VisitBillOfLadingPackagingId.$touch();
      this.$v.ClientName.$touch();
      this.$v.QuantityPlanning.$touch();
      this.$v.QuantityStowageRate.$touch();
      this.$v.ValidateQuantity.$touch();

    
      this.ValidateQuantity = Number(this.ValidateQuantity).toFixed(2);
      if (this.$v.PackagingId.$error || this.$v.Quantity.$error) {
        throw this.$t('label.errorsPleaseCheck');
      }
      if (!this.isEditPackaging && this.PackagingJsonList.some(item => item.PackagingId == this.PackagingId && item.VisitBillOfLadingPackagingId == (this.VisitBillOfLadingPackagingId ?? ''))) {
        throw this.$t('label.ItemPreviouslyRegistered');
      }
      if (this.Quantity > this.ValidateQuantity) {
        throw this.$t('label.quantityRemainError');
      }
      if (this.VisitBillOfLadingPackagingId) {
        let data = this.packagingList.find((val) => val.PackagingId == this.PackagingId);
        let QuantityStowageRate = data?.QuantityStowageRate && this.isEdit ? ((data?.QuantityStowageRate ?? 0) - this.Quantity - (this.stowageItem?.PackagingJson?.filter(item => item.PackagingId == this.PackagingId)?.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0) ?? 0)) : (data?.QuantityStowageRate ?? 0);
        let PackagingJsonFilter = this.PackagingJsonList.filter(item => item.PackagingId == this.PackagingId && (this.isEditPackaging ? (item.VisitBillOfLadingPackagingId != this.VisitBillOfLadingPackagingId) : true) && item.Status != 0)
        if(PackagingJsonFilter.length != 0) {
          QuantityStowageRate += PackagingJsonFilter.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0)
        }
        if ((data.QuantityPlanning - QuantityStowageRate - NumberFormater.setNum(this.Quantity)) < 0) {
          throw this.$t('label.quantityRemainPackaging');
        }
      }
     
      if (this.isEditPackaging) {
        this.PackagingJsonList = this.PackagingJsonList.map(item => {
          if (item.PackagingId == this.PackagingId && item.VisitBillOfLadingPackagingId == (this.VisitBillOfLadingPackagingId ?? '')) {
            return {
              ...item,
              Quantity: this.Quantity ? NumberFormater.setNum(this.Quantity) : 0,
              Status: 1,
            }
          } else {
            return item;
          }
        })
      } else {
        let packagingData =  this.packagingList.find( (val) =>  val.PackagingId == this.PackagingId);
        this.PackagingJsonList.push({
          IdX: this.PackagingJsonList.length > 0 ? this.PackagingJsonList[this.PackagingJsonList.length - 1].IdX + 1 : 1,
          PackagingId: this.PackagingId ?? '',
          VisitBillOfLadingPackagingId: this.VisitBillOfLadingPackagingId ?? '',
          NroBl: this.VisitBillOfLadingPackagingId ? (this.BlList?.find(item => item.VisitBillOfLadingPackagingId == this.VisitBillOfLadingPackagingId)?.NroBl ?? 'N/A') : 'N/A',
          ClientName: this.ClientName ? this.ClientName : 'N/A',
          PackagingNameEs: packagingData?.PackagingNameEs ?? 'N/A',
          PackagingNameEn: packagingData?.PackagingNameEn ?? 'N/A',
          Quantity: this.Quantity ? NumberFormater.setNum(this.Quantity) : 0,
          Status: 1,
        });
      }
      this.clearFilters();
    } catch (error) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    }
  }

  function clearFilters() {
    this.PackagingId = '';
    this.ClientName = '';
    this.VisitBillOfLadingPackagingId = '';
    this.Quantity = 0;
    this.QuantityPlanning = 0;
    this.QuantityStowageRate = 0;
    this.ValidateQuantity = 0;
    this.isEditPackaging = false;

    this.$v.PackagingId.$reset();
    setTimeout(() => {
      this.$v.Quantity.$reset();
    }, 10);

  }

  function EditPackaging(item){
    this.isEditPackaging = true;
    this.PackagingId = item.PackagingId;
    this.VisitBillOfLadingPackagingId = item.VisitBillOfLadingPackagingId ?? '';
    this.ClientName = item.ClientName ?? '';
    this.Quantity = NumberFormater.setNum(item.Quantity);
    this.getQuantityData(item.PackagingId, false);
 
    // this.$v.PackagingId.$touch();
    // this.$v.Quantity.$touch();
    // this.$v.QuantityPlanning.$touch();
    // this.$v.QuantityStowageRate.$touch();
    // this.$v.ValidateQuantity.$touch();
    this.$v.$touch();
  }

  function DeletePackaging(item) {
    this.$swal
        .fire(this.alertProperties({
            text: `${this.$t('label.deleteQuestion')}?`,
        }))
      .then((result) => {
      if (result.isConfirmed) {
        if (item.VisitCraneDetailPackagingId) {
          this.PackagingJsonList = this.PackagingJsonList.map(Packaging => {
            if (Packaging.VisitCraneDetailPackagingId == item.VisitCraneDetailPackagingId) {
              return {
                ...Packaging,
                Status: 0,
              }
            } else {
              return Packaging;
            }
          })
        }else{
          this.PackagingJsonList = this.PackagingJsonList.filter(e => e.IdX != item.IdX);
        }
        this.clearFilters();
      }
    });
  }

  function statusSelectColor() {
    return this.generalCargo.Status === 1;
  }

  async function getData(val){
    this.VisitCraneDetailId = val.VisitCraneDetailId ?? '',
    this.VisitCraneDetailPackagingId = val.VisitCraneDetailPackagingId ?? '';
    this.generalCargo.TransactionDate = val.TransactionDate ?? '';
    this.generalCargo.VisitCraneId = val.VisitCraneId ?? '';
    this.generalCargo.VesselUbicationId = val.VesselUbicationId ?? '';
    this.generalCargo.VesselHoldId = val.VesselHoldId ?? '';
    this.generalCargo.VesselHoldHatchCId = val.VesselHoldHatchCId ?? '';
    this.generalCargo.MovStowageId = val.MovStowageId ?? '';
    this.generalCargo.Status = val.Status ? 1:0;
    this.PackagingJsonList = val.PackagingJson && val.PackagingJson.length!=0 ? 
      val.PackagingJson.map((item, index) => {
        return {
          ...item,
          IdX: index+1,
          NroBl: item?.VisitBlPackagingInfoJson?.[0]?.NroBl ?? 'N/A',
          VisitBillOfLadingPackagingId: item?.VisitBlPackagingInfoJson?.[0]?.VisitBillOfLadingPackagingId ?? '',
          ClientName: item?.VisitBlPackagingInfoJson?.[0]?.ClientName ?? 'N/A',
          Status: 1,
        }
      })
      : 
      [];
    this.$v.Quantity.$reset();
    await this.getHoldList(this.generalCargo.VisitCraneId);
    this.$v.Quantity.$reset();
    this.$v.generalCargo.$touch();
  }

  async function ValidateDate(){
    try {
      if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)<this.ValidPreviousDate && this.itinerarySelected.Ata!=undefined) {
          throw this.$t('label.ErrorAtaPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd!=undefined) {
          throw this.$t('label.ErrorAtdPleaseCheck');
      }
      if(this.generalCargo.TransactionDate != ''){
        if (DateFormater.formatDateTimeWithoutSlash(this.generalCargo.TransactionDate)>this.ValidLaterDate && this.itinerarySelected.Atd==undefined) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }
      }
    }catch (error) {
      this.notifyError({text: error });
    }
  }

  function FormatNumber(number, decimal) {
    return NumberFormater.formatNumber(number, decimal);
  }

  //computed
  function craneOptions(){
    const chart = [
        {
            value: "",
            label: this.$t('label.select'),
        },
    ];
    this.craneList.map((item) => {
        chart.push({
            label: item.CraneAlias,
            value: item.VisitCraneId,
        });
    });
    return chart;
  }

  function holdOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.holdList.map((e) =>{
      chart.push({
        value: e.VesselHoldId,
        label: e.VesselHoldName,
      })
    })
    return chart;
  }

  function vesselHatchOptions(){
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.VesselHatchList.map((e) =>{
      chart.push({
        value: e.VesselHoldHatchCId,
        label: e.HatchCoverName,
      })
    })
    return chart;
  
  }

  function directDischargeOptions(){
    return [
        { 
            value: '', 
            label: this.$t('label.select')
        },
        { 
            value: true, 
            label: this.$t('label.yes')
        },
        { 
            value: false, 
            label: 'NO'
        }
    ];
  }

  function ubicationOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.ubicationList.map((e) => {
      chart.push({
        value: e.VesselUbicationId,
        label: e[`VesselUbicationName${_lang}`]
      });
    })
    return chart;
  }

  function movementStowageOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    const chart = [
        {
          value: "",
          label: this.$t('label.select'),
        },
    ];
      this.generalCargoMovStowageList.map((item) => {
        chart.push({
            label: item[`MovStowageName${_lang}`],
            value: item.MovStowageId,
        });
      });
    return chart;
  }

  function packagingOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
      value: '',
      label: this.$t('label.select'),
    }]
    this.packagingList.map((e) => {
      chart.push({
        value: e.PackagingId,
        label: e[`PackagingName${_lang}`]
      });
    })
    return chart;
  }

  function BlOptions(){
    const chart = [
        {
          value: "",
          label: this.$t('label.select'),
        },
    ];
      this.BlList.map((item) => {
        chart.push({
            label: item.NroBl,
            value: item.VisitBillOfLadingPackagingId,
        });
      });
    return chart;
  }

  
  function Packagingfields(){
    return [
      { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
      { key: 'PackagingName', label: this.$t('label.packaging'), _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
      { key: 'NroBl', label: this.$t('label.bl'), _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
      { key: 'ClientName', label: this.$t('label.client'), _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
      { key: 'Quantity', label: this.$t('label.quantity'), _style:'width:18%;', _classes:"center-cell text-center", sorter: true, filter: true},
      { key: 'options', label: '', _style: 'width:1%; min-width:90px; text-align:center;', sorter: false, filter: false },
    ];
  }

  function ComputedPackaging() {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let List = this.PackagingJsonList.filter(item => item.Status !== 0)
    return List.map((item, index) => {
      return {
        ...item,
        Nro: index+1,
        PackagingName: item[`PackagingName${_lang}`] ?? 'N/A',
        Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
      }
    });
  }

  function titleModal() {
    return  this.isEdit ? this.$t('label.edit')+' '+this.$t('label.dischargeConfirmation') :
            this.$t('label.nueva')+' '+this.$t('label.dischargeConfirmation');
  }

  function ValidateHoldDeck() {
    if (typeof this.generalCargo.VesselUbicationId == 'string') {
      return this.generalCargo.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VESSEL_UBICATION_ID_DECK_HOLD;
    }else{
      return false;
    }
  }

  function ValidateHold() {
    if (typeof this.generalCargo.VesselUbicationId == 'string') {
      return this.generalCargo.VesselUbicationId?.toUpperCase() == process.env.VUE_APP_VISIT_VESSEL_UBICATION_ID_HOLD;
    }else{
      return false;
    }
  }


  function calculateQuantityStowage() {
    return NumberFormater.setNum(this.QuantityStowageRate) + NumberFormater.setNum(this.Quantity);
  }

  function calculateQuantityRemainder() {
    return NumberFormater.setNum(this.ValidateQuantity) - NumberFormater.setNum(this.Quantity);
  }
 
export default {
  name: 'modal-stowage',
  data,
  props: { modal: Boolean, stowageItem: Object, isEdit: Boolean },
  components: { Money },
  mixins: [ModalMixin, mixinGeneral, mixinServicio],
  directives: UpperCase,
  validations(){
    return stowageValidation(this.ValidLaterDate, this.ValidPreviousDate, this.ValidateHoldDeck,
    this.ValidateHold, this.ValidateQuantity);
  },

  watch: {
    modal: async function (val) {
      setTimeout(() => {
        this.$v.$reset();
      }, 10);
      this.showModal = val;
      if(val){
        await this.getDateRange();
        await this.getListApis();
        if (this.isEdit) {
           await this.getData(this.stowageItem);
        }
      
      }
    },
    Quantity: function (val) {
      try {
        this.ValidateQuantity = Number((this.QuantityPlanning - this.QuantityStowageRate).toFixed(2));

        if (NumberFormater.setNum(val) > 0) {
          if (NumberFormater.setNum(val) > NumberFormater.setNum(this.ValidateQuantity)) {
            throw this.$t('label.quantityRemainError');
          }
        }
      } catch (error) {
          this.notifyError({text: error});
      }
    },
  },
  methods: {
    closeModal,
    getCraneList,
    filterCrane,
    filterVesselHatch,
    filterVesselUbication,
    filterDetail,
    filterQuantity,
    filterBl,
    getQuantityData,
    getVesselHatch,
    getHoldList,
    getMovementStowageList,
    getPackagingList,
    getUbicationList,
    getListApis,
    refreshComponent,
    submit,
    validateDateRange,
    getDateRange,
    SubmitPackaging,
    clearFilters,
    EditPackaging,
    DeletePackaging,
    statusSelectColor,
    getData,
    ValidateDate,
    FormatNumber,
  },
  computed: {
    craneOptions,
    holdOptions,
    vesselHatchOptions,
    directDischargeOptions,
    ubicationOptions,
    movementStowageOptions,
    packagingOptions,
    BlOptions,
    Packagingfields,
    ComputedPackaging,
    titleModal,
    ValidateHoldDeck,
    ValidateHold,
    calculateQuantityStowage,
    calculateQuantityRemainder,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      itinerarySelected: state => state.visitas.itinerarySelected,
      branch: state => state.auth.branch,
    }),
  },
};
</script>
<style lang="scss">
.status-open {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #42aa91 !important;
  }

}
.status-close {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #FF8000 !important;
  }
  .form-control:focus {
    border-color: #FF8000 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #FF8000 !important;
    box-shadow: 0 0 0 0.1rem #FF8000 !important;
  }
}
.status-inactive {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #e1373f !important;
  }
  .form-control:focus {
    border-color: #e1373f !important;
    -webkit-box-shadow: 0 0 0 0.1rem #e1373f !important;
    box-shadow: 0 0 0 0.1rem #e1373f !important;
  }
}
.status-EndOperation {
  .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #1A3760 !important;
  }
  .form-control:focus {
    border-color: #1A3760 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #1A3760 !important;
    box-shadow: 0 0 0 0.1rem #1A3760 !important;
  }
}

.condition-label label:before {
    color: #e55353;
    content: "* ";
    display: inline;
}
</style>
