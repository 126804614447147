<template>
    <div>
        <CModalExtended
            size="xl"
            color="dark"
            @update:show="closeModal(false, false)"
            :closeOnBackdrop="false"
            :title="title"
            :show.sync="ModalActive"
            class="modal-extended"
        >
            <loading-overlay
                :active="isLoading"
                :is-full-page="true"
                loader="bars"
            />
                    <CRow>
                        <CCol sm="12" lg="6">
                            <CRow>
                                <CCol sm="12">  
                                    <div class="position-relative">
                                        <div class="form-group form-row" rol="group">
                                            <label class="required text-right col-form-label-sm col-sm-12 col-lg-4 mb-0" for="tpGrua">{{$t('label.craneType')}}</label>
                                            <div class="col-sm-12 col-lg-8 input-group">
                                                <v-select 
                                                    id="v-select-small"
                                                    :options="tpGruasOptions"
                                                    label="TpCraneName"
                                                    :placeholder="$t('label.toSelect')"
                                                    style="width: 100%;"
                                                    :class="TpGrua ? 'select-client--correct' 
                                                        : (valid ? 'select-client--error' : '')"
                                                    v-model="TpGrua"
                                                    :disabled="isTpGruasEmpty"
                                                />
                                                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="TpGrua?false:valid">
                                                    {{$t('label.required')}}
                                                </CCol>
                                            </div>
                                        </div>
                                    </div>                          
                                </CCol>
                                <CCol sm="12">   
                                    <CInput
                                        size="sm"
                                        v-model.trim="$v.grua.CraneName.$model"
                                        v-uppercase
                                        :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                                        :invalid-feedback="errorMessage($v.grua.CraneName)"
                                        :is-valid="hasError($v.grua.CraneName)"
                                        :label="$t('label.name')"
                                        :placeholder="$t('label.craneName')"
                                        addLabelClasses="text-right required"
                                        maxlength="150"
                                    >
                                    </CInput>  
                                </CCol>
                                <CCol sm="12">   
                                    <CSelect
                                        size="sm"
                                        v-model="$v.grua.FgOperational.$model"
                                        :label="$t('label.operativa')+'?'"
                                        :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                                        :is-valid="hasError($v.grua.FgOperational)"
                                        :options="operativaOptions"
                                        addLabelClasses="text-right required"
                                    /> 
                                </CCol>
                            </CRow>   
                        </CCol>
                        <CCol sm="12" lg="6"> 
                            <CRow>
                                <CCol sm="12"> 
                                    <div class="form-group form-row ">
                                        <label class="text-right col-sm-12 col-lg-6 required col-form-label-sm mb-0">{{ $t('label.maxWeight')+' (TON)' }}</label>
                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                            <money
                                                v-uppercase
                                                v-model.trim="grua.MaxWeigth"
                                                v-bind="measure" 
                                                :class="ValidateFormControl(grua.MaxWeigth)"
                                            >
                                            </money>
                                            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((grua.MaxWeigth == 0 || grua.MaxWeigth == '0,00') && valid==true)">
                                                {{$t('label.required')}}
                                            </CCol>
                                        </div>
                                    </div>                           
                                </CCol>
                                <CCol sm="12">
                                    <div class="form-group form-row">
                                        <label class="text-right col-sm-12 col-lg-6 required col-form-label-sm mb-0">{{ $t('label.maxRange')+' (MTR)' }}</label>
                                        <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                            <money
                                                v-uppercase
                                                v-model.trim="grua.MaxHeigth"
                                                v-bind="measure" 
                                                :class="ValidateFormControl(grua.MaxHeigth)"
                                            >
                                            </money>
                                            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((grua.MaxHeigth == 0 || grua.MaxHeigth == '0,00') && valid==true)">
                                                {{$t('label.required')}}
                                            </CCol>
                                        </div>
                                    </div>                            
                                </CCol>
                                <CCol sm="12" v-if="isEdit">   
                                    <CSelect
                                        size="sm"
                                        :value.sync="grua.Status"
                                        :is-valid="statusSelectColor"
                                        :label="$t('label.status')"
                                        :horizontal="{ label: 'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                                        :options="statusOptions"
                                        addLabelClasses="text-right"
                                    />                       
                                </CCol>
                            </CRow> 

                        </CCol>
                    </CRow>
            <template #footer>
                <CButton
                    color="add"
                    class="m-2"
                    @click="statusConfirmation"
                    :disabled="isSubmit"
                >
                    <div>
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal(false, false)"
                    class="m-2"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </template>
        </CModalExtended>
    </div>
</template>

<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import BuqueGruaValidations from '@/_validations/buque/BuqueGruaValidations';
    import { NumberFormater } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    import { mapState } from 'vuex';
    import { Money } from "v-money";

    //Data:
    function data() {
        return {
            ModalActive: false,
            isSubmit: false,
            isLoading: false,
            valid: false,
            items: [],
            CraneName: '',
            grua: {
                VesselCraneId: '',
                VesselId: '',
                TpCraneId: '',
                CraneName: '',
                MaxWeigth: '',
                MaxHeigth: '',
                FgOperational: 1,
                Status: 1
            },
            TpGrua: null,
            tipoGruas: [],
            oldFgAct: 1,

            measure: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
                maxlength: 18,
            },
        }
    }

    //Methods:
    function submit() {
        if(!this.isEdit)
            this.addGrua();
        else
            this.editGrua();
    }
    function addGrua() {
        try {
            this.isLoading = true;
            this.isSubmit = true;
            this.valid = true;
            this.$v.$touch();
            let MinWeigth = NumberFormater.setNum(this.grua.MaxWeigth) > 0 ? false : true;
            let MinHeigth = NumberFormater.setNum(this.grua.MaxHeigth) > 0 ? false : true;
            if (this.$v.$error|| MinWeigth || MinHeigth) {
                throw this.$t('label.errorsPleaseCheck');
            };
            let VesselCraneJson = {...this.formatedData()};

            this.$http.ejecutar('POST', 'VesselCrane-insert', VesselCraneJson, { root: 'VesselCraneJson' })
            .then(response => {
                this.isLoading = false;
                this.$notify({
                    group: 'container',
                    title: this.$t('label.success'),
                    text: response.data.data[0].Response,
                    type: "success"
                });
                this.resetInputs();
                this.closeModal(false, true);
                this.isSubmit = false;
            }).catch(err => {
                this.isLoading = false;
                this.isSubmit = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
        } catch (error) {
            this.isLoading = false;
            this.isSubmit = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }
    function editGrua() {
        try {
            this.isLoading = true;
            this.isSubmit = true;
            this.valid = true;
            this.$v.$touch();
            let MinWeigth = NumberFormater.setNum(this.grua.MaxWeigth) > 0 ? false : true;
            let MinHeigth = NumberFormater.setNum(this.grua.MaxHeigth) > 0 ? false : true;
            if (this.$v.$error|| MinWeigth || MinHeigth) {
                throw this.$t('label.errorsPleaseCheck');
            };
            let VesselCraneJson = {...this.formatedData()};

            this.$http.ejecutar('PUT', 'VesselCrane-update', VesselCraneJson, { root: 'VesselCraneJson' })
            .then(response => {
                this.isLoading = false;
                this.isSubmit = false;
                this.$notify({
                    group: 'container',
                    title: '¡'+this.$t('label.success')+'!',
                    text: response.data.data[0].Response,
                    type: "success"
                });
                this.resetInputs();
                this.closeModal(false, true);
            }).catch(err => {
                this.isLoading = false;
                this.isSubmit = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
        } catch (error) {
            this.isLoading = false;
            this.isSubmit = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }
    function resetInputs() {
        this.grua.VesselCraneId = '';
        this.grua.CraneName = '';
        this.grua.TpCraneId = '';
        this.grua.FgOperational = 1;
        this.grua.MaxWeigth = '';
        this.grua.MaxHeigth = '';
        this.grua.Status = 1;
        this.TpGrua = null;
        this.valid = false;
        this.$v.$reset();
    }
    function ValidateFormControl(value) {
        value = NumberFormater.setNum(value);
        if (value == 0 && this.valid==true) {
            return 'form-control is-invalid';
        }else{
            if(value == 0 && this.valid==false){
                return 'form-control';
            }else{
                return 'form-control is-valid';
            }
        }
    }
    function handleEdit(item) {
        this.grua.VesselCraneId = item.VesselCraneId;
        this.grua.CraneName = item.CraneName;
        this.grua.TpCraneId = item.TpCraneId;
        this.grua.FgOperational = item.FgOperational?1:0;
        this.grua.Status = item.FgActVesselCrane?1:0;
        this.oldFgAct = item.FgActVesselCrane?1:0;

        this.grua.MaxWeigth = item.MaxWeigth,
        this.grua.MaxHeigth = item.MaxHeigth,
        this.TpGrua = {
            TpCraneName: item.TpCraneName,
            TpCraneId: item.TpCraneId,
            MaxWeigth: item.MaxWeigth,
            MaxHeigth: item.MaxHeigth
        };
        this.valid = true;
        this.$v.$touch();
    }
    function closeModal(filtre, Refresh){
        this.$emit('Close-Modal', filtre, Refresh);
    }
    function formatedData() {
        if(!this.isEdit){
            return {
            VesselId: this.grua.VesselId,
            TpCraneId: this.TpGrua.TpCraneId,
            CraneName: this.grua.CraneName,
            MaxWeigth: NumberFormater.setNum(this.grua.MaxWeigth),
            MaxHeigth: NumberFormater.setNum(this.grua.MaxHeigth),
            FgOperational: this.grua.FgOperational
            }
        } else {
            return {
            ...this.grua,
            TpCraneId: this.TpGrua.TpCraneId,
            MaxWeigth:  NumberFormater.setNum(this.TpGrua.MaxWeigth),
            MaxHeigth:  NumberFormater.setNum(this.TpGrua.MaxHeigth),
            };
        }
    }
    function statusSelectColor(){
        return this.grua.Status === 1;
    }
    function statusConfirmation(){
        if(!this.isEdit)
            this.submit();
        else{
            let status = this.oldFgAct;
            if(this.grua.Status === status)
            this.submit();
            else{
            this.$swal.fire({
                text: `${this.$t('label.changeStatusQuestion')}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#42AA91',
                cancelButtonColor: '#E1373F',
                confirmButtonText: this.$t('button.confirm'),
                cancelButtonText: this.$t('button.cancel'),
            }).then((result) => {
                if (result.isConfirmed) {
                this.submit();
                }
            });
            }
        }
    }
    function handleTpGruaSubmit() {
        this.getTpGruas();
    }
    function getTpGruas() {
        this.isLoading = true;
        this.$http.ejecutar('GET', 'TpCrane-list', { Filter: 'ACTIVO' })
        .then(response => {
            this.tipoGruas = response.data.data;
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        }).then(() => {
            this.isLoading = false;
        });
    }
    //computed
    function statusOptions() {
        return [
            {
                value: 1,
                label: this.$t('label.ACTIVO')
            },
            {
                value: 0,
                label: this.$t('label.INACTIVO')
            }
        ]
    }
    function operativaOptions() {
        return [
            {
                value: 1,
                label: this.$t('label.yes')
            },
            {
                value: 0,
                label: 'NO'
            }
        ]
    }
    function submitText() {
        return this.$t('button.accept')
    }
    function isTpGruasEmpty(){
        return this.tipoGruas.length == 0;
    }
    function tpGruasOptions() {
        return this.tipoGruas.map((tpGrua) => Object.assign({}, tpGrua, {
            label: tpGrua.TpCraneName,
            value: tpGrua.TpCraneId
        }));
    }
    function isSubmitValid(){
        return this.$v.$invalid || this.isSubmit;
    }
    function title() {
        if (this.isEdit) {
            return this.$t('label.edit')+' '+this.$t('label.crane')+': '+(this.CraneName?this.CraneName:'');
        }else{
            return this.$t('label.nueva')+' '+this.$t('label.crane');
        }
    }

    //watch
    function modal(newValue){
        this.ModalActive = newValue;
        if(newValue){
            this.grua.VesselId = this.itinerarySelected.VesselId,
            this.getTpGruas();
            if(this.isEdit){
                this.isLoading = true;
                this.$http.ejecutar('GET', 'VesselCrane-by-id',{ VesselCraneId: this.CraneId }, '' )
                    .then(response => {
                        let Information = response.data.data;
                        if(Information.length !== 0){
                            this.grua.VesselCraneId = Information[0].VesselCraneId;
                            this.CraneName = Information[0].CraneName;
                            this.grua.CraneName = Information[0].CraneName;
                            this.grua.TpCraneId = Information[0].TpCraneId;
                            this.grua.FgOperational = Information[0].FgOperational?1:0;
                            this.grua.Status = Information[0].FgActVesselCrane?1:0;
                            this.oldFgAct = Information[0].FgActVesselCrane?1:0;

                            this.grua.MaxWeigth = Information[0].MaxWeigth,
                            this.grua.MaxHeigth = Information[0].MaxHeigth,
                            this.TpGrua = {
                                TpCraneName: Information[0].TpCraneName,
                                TpCraneId: Information[0].TpCraneId,
                                MaxWeigth: Information[0].MaxWeigth,
                                MaxHeigth: Information[0].MaxHeigth
                            };
                            this.$v.$touch();
                        }
                        this.isLoading = false;
                    }).catch(err => {
                        this.isLoading = false;
                        this.$notify({
                            group: 'container',
                            title: '¡Error!',
                            text: err,
                            type: "error"
                        });
                    });
            }
        }else{
            this.resetInputs();
        }
    }
    export default {
        name:'app-gruas-crud',
        mixins: [GeneralMixin],
        data,
        props: {modal: Boolean, isEdit: Boolean, CraneId: null},
        validations: BuqueGruaValidations,
        directives: UpperCase,
        methods: {
            ValidateFormControl,
            closeModal,
            addGrua,
            editGrua,
            resetInputs,
            submit,
            handleEdit,
            formatedData,
            statusSelectColor,
            statusConfirmation,
            handleTpGruaSubmit,
            getTpGruas
        },
        components: {
            Money,
        },
        computed:{
            title,
            statusOptions,
            operativaOptions,
            submitText,
            isTpGruasEmpty,
            tpGruasOptions,
            isSubmitValid,
            ...mapState({
                itinerarySelected: state => state.visitas.itinerarySelected,
            })
        },
        watch:{
            modal,
            TpGrua: function(newTpGrua) {
                if(newTpGrua){
                    this.grua.MaxWeigth = newTpGrua.MaxWeigth;
                    this.grua.MaxHeigth = newTpGrua.MaxHeigth;
                }else{
                    this.grua.MaxWeigth = 0;
                    this.grua.MaxHeigth = 0;
                }
            },
        }
    }
</script>

<style lang="scss">
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
                vertical-align: middle !important;
            }
        }
    }
</style>