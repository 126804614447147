<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="titleModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="uploadDocument"
    >
     
       <CRow>        
        <CCol sm="12" lg="12" class="mb-2">
          <vue-dropzone 
              ref="myVueDropzone" 
              id="dropzone" 
              :options="dropzoneOptions" 
              :useCustomSlot="true"
              v-on:vdropzone-files-added="sendingEvent"
              v-on:vdropzone-removed-file="deleteFile"
              v-on:vdropzone-error="DropzoneError"
          > 
              <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                  <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
              </div>
          </vue-dropzone>

        </CCol>        
        <CCol sm="12" lg="10">
            <CInput
              :label="$t('label.description')"
              :horizontal="{label:'col-sm-9 col-lg-4',input:'col-sm-11 col-lg-8'}"
              maxlength="250"
              v-model.trim="document.Description"
              addLabelClasses="text-right required"
              :is-valid="hasError($v.document.Description)"
              :invalid-feedback="errorMessage($v.document.Description)"
            />
        </CCol>
        <CCol sm="11" lg="2">
          <CButton 
            color="add" 
            square 
            size="sm"
            :disabled="isSubmit"
            @click="submit"
          >
            <CIcon name="checkAlt"/>
          </CButton>
        </CCol>

        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="items"
            :fields="fields"
            :loading="loadingOverlay"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="tableText.itemsPerPage"
            hover
            small
            sorter
            pagination
          >
            <template #loading>
              <loading/>
            </template>
            <template #Down="{item}">
              <td class="py-2 center-cell">
                <a class="btn btn-sm btn-watch" 
                  style="margin-right:2px;" 
                  v-c-tooltip="{
                    content: $t('label.ShowDocument'),
                    placement: 'top-end',
                  }" 
                  target='_blank' 
                  :href="item.Down" 
                >
                  <CIcon name="eye" />
                </a>
                <CButton 
                  color="wipe" 
                  square 
                  size="sm" 
                  v-c-tooltip="{
                    content: $t('label.delete'),
                    placement: 'top-end',
                  }"
                   @click="deleteDocument(item)"
                  >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="wipe" size="sm" @click="uploadDocument= false">
            <CIcon name="x"/>&nbsp;
            {{$t('button.exit')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>
<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }
    .icon{
        background-color: null; 
        border: 0px;
    }
</style>

<script>
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import inspectionValidation from '@/_validations/inspection/inspectionValidation';


let user = JSON.parse(localStorage.getItem('user'));

function fields(){ 

return[
  { key: 'Nro', label: '#', _style: 'width:3%; text-align:center',filter:false }, 
  { key: 'Description', label: this.$t('label.document'), _classes: 'text-uppercase', _style:'width:50%' },
  { key: 'Usuario', label:this.$t('label.user'),  _classes: 'text-uppercase', _style:'width:15%'  },
  { key: 'Fecha', label:this.$t('label.date'),  _classes: 'text-center text-uppercase', _style:'width:15%'  },
  { 
    key: 'Down', 
    label: '',
    sorter: false,
    filter: false,
    _style: 'width:10%; min-width:90px;',
    _classes:'center-cell'
  },  
]

};
function data () {
  return {
    items: [],
    visitCargoInspectionId: '',
    DocumentRoute: '',

    document: {
      Description: '',
      VisitCargoInspectionDocumentId: '',
    },

    uploadDocument: false,
    isSubmit: false,
    loading: false,
    loadingOverlay: false,
    titleModal: null,
    docFile: null,
    uploadPercent: 0,
    responsePercent: 0,
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
          this.removeAllFiles();
          this.addFile(files);
      },
      acceptedFiles: '.pdf, .doc, .docx, .xlsx',
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user.token}`
      }
    }
  }
}

async function getDocumentList() {
  this.loadingOverlay = true;
  this.items = [];
  let _this = this.$i18n.locale;   
  
  let listado = Array;
  await this.$http.get("VisitCargoInspDocument-list?VisitCargoId="+this.VisitCargoId+"&VisitCargoDamageInspectionId="+this.VisitCargoDamageInspectionId+"&VisitCargoInspectionId="+this.VisitCargoInspectionId)
  .then(response => {
    listado = [...response.data.data];
    let i = 1;
    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: i++,
      VisitCargoInspectionDocumentId: listado.VisitCargoInspectionDocumentId,
      Description: listado.Description,
      DocumentRoute: listado.DocumentRoute ? listado.DocumentRoute.replace('Public/Commodity/', '', 'i'):'N/A',
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      Status: listado.Status ? listado.Status : '',
      Down: listado.DocumentRoute ? `${this.$store.getters["connection/getBase"]}${listado.DocumentRoute.replace('Public/', '', 'i')}`:'N/A',
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function refreshComponent() {
  this.document.Description    = '';
  this.document.VisitCargoInspectionDocumentId    = '';
  this.$refs.myVueDropzone.removeAllFiles();
  this.$emit('closeModal');
  this.$nextTick(() => { this.$v.$reset() })
}

function sendingEvent(files){
 // this.$refs.myVueDropzone.removeFile(files);
  if(files[0].name) this.docFile = files[0];
}

function deleteFile(file) {
  this.docFile = null;
}
function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}

function deleteDocument(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')+' '} ${item.Description}?`,
      }))
  .then((result) => {
    if (result.isConfirmed) {
      this.document.VisitCargoInspectionDocumentId=item.VisitCargoInspectionDocumentId;
      this.document.Description=item.Description;
      this.DocumentRoute=item.DocumentRoute;

    this.Loading = true;
    let res = [];
    let metodo = '';
    let ruta = '';
    let FilesJson = [];
    
    //ASIGNACION DE VARIABLES PARA submit
    if (this.VisitCargoInspectionDocumentId!=='') {
      FilesJson = {
        VisitCargoInspectionDocumentId: this.document.VisitCargoInspectionDocumentId,
        VisitCargoInspDamageId: this.VisitCargoDamageInspectionId,
        VisitCargoInspectionId: this.VisitCargoInspectionId,
        Description: this.document.Description,
        FileValue: this.DocumentRoute,
        Status: 0,
      };
      metodo = 'PUT';
      ruta = "VisitCargoInspDocument-update";
    }
    
    this.$http.ejecutar(metodo, ruta, FilesJson, { root: 'FilesJson' })
      .then(response => {
        res = [...response.data.data];
        this.refreshComponent();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.Loading= false;
        this.getDocumentList();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.Loading= false;
      });
    }
  });  
}

function submit() {
  try {
    this.isSubmit = true;
    this.Loading = true;
    this.$v.document.$touch();
    const files = this.$refs.myVueDropzone.getQueuedFiles();
    if (this.$v.document.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    if(files.length === 0){
      throw this.$t('label.documentQuestion'); 
    }
    let formData = new FormData();
    formData.append('VisitCargoInspectionDocumentId', this.document.VisitCargoInspectionDocumentId);
    formData.append('File', this.docFile);
    this.$http.post("VisitCargoInspDocument-insert", formData, {
        baseURL: `${this.$store.getters["connection/getApiBase"]}files/`,
        onUploadProgress: function( progressEvent ) {
          this.uploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this),
        onDownloadProgress: function( progressEvent ) {
          this.responsePercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this)
    }).then((responseImage) => {
      this.DocumentRoute = responseImage.data.data.files[0].path;
      let res = [];
      let metodo = '';
      let ruta = '';
      let FilesJson = [];

      FilesJson = {
        VisitCargoInspDamageId: this.VisitCargoDamageInspectionId,
        VisitCargoInspectionId: this.VisitCargoInspectionId,
        Description: this.document.Description,
        FileValue: this.DocumentRoute,
      };
      metodo = 'POST';
      ruta = "VisitCargoInspDocument-insert";
      
      this.$http.ejecutar(metodo, ruta, FilesJson, { root: 'FilesJson' })
      .then(response => {
        res = [...response.data.data];
  
        this.refreshComponent();
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: res[0].Response,
          type: "success"
        });
        this.isSubmit = false;
        this.Loading= false;
        this.getDocumentList();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.isSubmit = false;
        this.Loading= false;
      });
    }).catch((error) => {
      this.isSubmit = false;
      this.Loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    })
  } catch (e) {
    this.isSubmit = false;
    this.Loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}
//Computeds

export default {
  name: 'modal-upload-document',
  mixins: [General],
  data,
  props:{
    modal:null
  }, 
  components: {
    vueDropzone: vue2Dropzone
  },
  validations: inspectionValidation,
  methods: {
    getDocumentList,
    refreshComponent,
    sendingEvent,
    deleteFile,
    DropzoneError,
    deleteDocument,
    submit,
  },
  watch: {
    modal: function () {
      if (this.modal) {
        this.refreshComponent();
        this.titleModal= this.$t('label.uploadDocuments') + ": "+this.modal.ContainerCode;
        this.uploadDocument = true;
        this.VisitCargoId = this.modal.VisitCargoId;
        this.VisitCargoDamageInspectionId = this.modal.VisitCargoInspDamageId;
        this.VisitCargoInspectionId = this.modal.VisitCargoInspectionId;
        this.getDocumentList();
        this.$emit('closeModal');
      }
    }
  },
  computed: {
    fields
}
}
</script>
<style scoped>

</style>